import type { SelectProps } from '@pledge-earth/web-components';
import {
  // eslint-disable-next-line no-restricted-imports -- blocked by PPL Combobox
  Select,
} from '@pledge-earth/web-components';
import type { ForwardedRef } from 'react';
import { ChevronUpDownMinorIcon } from '@pledge-earth/product-language';

import { genericForwardRef } from '../../../utils/genericForwardRef';

import './MultiSelectAutocomplete.scss';

export type MultiSelectProps = {
  renderOptions: () => React.ReactNode;
} & Omit<
  SelectProps<any, any>,
  | 'className'
  | 'onSearch'
  | 'onFocus'
  | 'onSelect'
  | 'loading'
  | 'mode'
  | 'optionLabelProp'
  | 'showArrow'
  | 'showSearch'
  | 'allowClear'
  | 'maxTagCount'
  | 'suffixIcon'
>;

/**
 * Select component with support for lazy-loading options from a provided query.
 */

export const MultiSelect = /* #__PURE__ */ genericForwardRef(
  // eslint-disable-next-line prefer-arrow-callback
  function MultiSelectAutocomplete(
    { renderOptions, ...selectProps }: MultiSelectProps,
    forwardedRef: ForwardedRef<any>,
  ) {
    return (
      <Select
        {...selectProps}
        ref={forwardedRef}
        className="MultiSelectAutocomplete"
        mode="multiple"
        optionLabelProp="label"
        showArrow={true}
        showSearch={true}
        allowClear={true}
        maxTagCount="responsive"
        suffixIcon={<ChevronUpDownMinorIcon />}
      >
        {renderOptions()}
      </Select>
    );
  },
);
