import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTimeISO: { input: string; output: string };
  Upload: { input: unknown; output: unknown };
};

export enum AccountStateEnum {
  Authorized = 'AUTHORIZED',
  CreateProfile = 'CREATE_PROFILE',
  Invited = 'INVITED',
  Provisioned = 'PROVISIONED',
  Registered = 'REGISTERED',
  Unauthorized = 'UNAUTHORIZED',
}

export type AddInventoryInput = {
  currency: Scalars['String']['input'];
  price_per_tonne: Scalars['Float']['input'];
  price_per_tonne_in_gbp?: InputMaybe<Scalars['Float']['input']>;
  project_id: Scalars['String']['input'];
  registry?: InputMaybe<Scalars['String']['input']>;
  registry_id?: InputMaybe<Scalars['String']['input']>;
  rrp_max?: InputMaybe<Scalars['Float']['input']>;
  rrp_min?: InputMaybe<Scalars['Float']['input']>;
  vintage: Scalars['String']['input'];
  weight_tonnes: Scalars['Float']['input'];
};

export type ApiKey = {
  __typename?: 'ApiKey';
  api_key: Scalars['String']['output'];
  created_date: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ApiLog = {
  __typename?: 'ApiLog';
  api_key: Scalars['String']['output'];
  date: Scalars['DateTimeISO']['output'];
  endpoint: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  request: Scalars['String']['output'];
  response: Scalars['String']['output'];
  response_code: Scalars['Float']['output'];
};

export type CarbonplanAssessment = {
  __typename?: 'CarbonplanAssessment';
  created_date: Scalars['DateTimeISO']['output'];
  data_stringify?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  updated_date: Scalars['DateTimeISO']['output'];
};

export type CargoWiseEAdaptorOutboundConfig = {
  __typename?: 'CargoWiseEAdaptorOutboundConfig';
  id: Scalars['ID']['output'];
  sender_id: Scalars['String']['output'];
};

export type Client = {
  __typename?: 'Client';
  address_1?: Maybe<Scalars['String']['output']>;
  address_2?: Maybe<Scalars['String']['output']>;
  billing_email_address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  client_function?: Maybe<ClientFunctionEnum>;
  country?: Maybe<Country>;
  currency?: Maybe<CurrencyEnum>;
  domains: Array<ClientDomain>;
  entitlements: Array<ClientSubscriptionEntitlement>;
  id: Scalars['ID']['output'];
  legal_name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  public_id: Scalars['String']['output'];
  region?: Maybe<Scalars['String']['output']>;
  tax_id_type?: Maybe<TaxIdTypeEnum>;
  tax_id_value?: Maybe<Scalars['String']['output']>;
  users: Array<ClientUser>;
  website?: Maybe<Scalars['String']['output']>;
  zip_code?: Maybe<Scalars['String']['output']>;
};

export type ClientDomain = {
  __typename?: 'ClientDomain';
  client: Client;
  created_date: Scalars['DateTimeISO']['output'];
  domain: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  updated_date: Scalars['DateTimeISO']['output'];
};

export enum ClientFunctionEnum {
  Carrier = 'CARRIER',
  FreightForwarder = 'FREIGHT_FORWARDER',
  IndependentSoftwareVendor = 'INDEPENDENT_SOFTWARE_VENDOR',
  Lsp = 'LSP',
  Other = 'OTHER',
  Shipper = 'SHIPPER',
}

export type ClientIntegration = {
  __typename?: 'ClientIntegration';
  cargowise_eadaptor_outbound_configs: Array<CargoWiseEAdaptorOutboundConfig>;
  configuration_stringified?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  public_id: Scalars['ID']['output'];
  secondary_system?: Maybe<ClientIntegrationSecondarySystemEnum>;
  status: ClientIntegrationStatusEnum;
  status_update_date: Scalars['DateTimeISO']['output'];
  status_update_requested_by?: Maybe<ClientUser>;
  system: ClientIntegrationSystemEnum;
  test: Scalars['Boolean']['output'];
  type?: Maybe<ClientIntegrationConfigurationTypeEnum>;
};

export enum ClientIntegrationConfigurationTypeEnum {
  HttpApi = 'HTTP_API',
}

export type ClientIntegrationInput = {
  configuration_stringified?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  secondary_system?: InputMaybe<ClientIntegrationSecondarySystemEnum>;
  status: ClientIntegrationStatusEnum;
  status_update_requested_by_client_user_id?: InputMaybe<
    Scalars['ID']['input']
  >;
  system: ClientIntegrationSystemEnum;
  test: Scalars['Boolean']['input'];
  type?: InputMaybe<ClientIntegrationConfigurationTypeEnum>;
};

export enum ClientIntegrationSecondarySystemEnum {
  ChainioCargowise = 'CHAINIO_CARGOWISE',
  ChainioDescartes = 'CHAINIO_DESCARTES',
  ChainioFlexport = 'CHAINIO_FLEXPORT',
  ChainioMagaya = 'CHAINIO_MAGAYA',
  ChainioOther = 'CHAINIO_OTHER',
}

export enum ClientIntegrationStatusEnum {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED',
}

export enum ClientIntegrationSystemEnum {
  Cargowise = 'CARGOWISE',
  CargowiseProxy = 'CARGOWISE_PROXY',
  Chainio = 'CHAINIO',
  EmailImport = 'EMAIL_IMPORT',
  IleapHost = 'ILEAP_HOST',
  ServerToServerFileExchange = 'SERVER_TO_SERVER_FILE_EXCHANGE',
}

export type ClientRole = {
  __typename?: 'ClientRole';
  id: Scalars['ID']['output'];
  name: ClientRoleEnum;
};

export enum ClientRoleEnum {
  Admin = 'ADMIN',
  Developer = 'DEVELOPER',
  Operations = 'OPERATIONS',
  Owner = 'OWNER',
  Viewer = 'VIEWER',
}

export type ClientSubscriptionEntitlement = {
  __typename?: 'ClientSubscriptionEntitlement';
  feature_id: Scalars['String']['output'];
  feature_type: Scalars['String']['output'];
  is_enabled: Scalars['Boolean']['output'];
  value: Scalars['String']['output'];
};

export type ClientUser = {
  __typename?: 'ClientUser';
  account_state: AccountStateEnum;
  created_date: Scalars['DateTimeISO']['output'];
  email: Scalars['String']['output'];
  first_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  last_name?: Maybe<Scalars['String']['output']>;
  role: ClientRole;
  updated_date: Scalars['DateTimeISO']['output'];
};

export type ConfigureSupplierEntitlementsForDataCollectionInput = {
  dataCollectionRequestPublicId: Scalars['String']['input'];
};

export type ConfirmRetirementInput = {
  retirement_id: Scalars['String']['input'];
  retirement_url: Scalars['String']['input'];
  serial_numbers: Scalars['String']['input'];
};

export type Country = {
  __typename?: 'Country';
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type CountryInput = {
  code: Scalars['String']['input'];
};

export type CreateClientInput = {
  address_1?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  billing_email_address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country: CountryInput;
  currency?: InputMaybe<CurrencyEnum>;
  domain: Scalars['String']['input'];
  legal_name: Scalars['String']['input'];
  owner_email: Scalars['String']['input'];
  owner_first_name: Scalars['String']['input'];
  owner_last_name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  tax_id_type: TaxIdTypeEnum;
  tax_id_value: Scalars['String']['input'];
  website?: InputMaybe<Scalars['String']['input']>;
  zip_code?: InputMaybe<Scalars['String']['input']>;
};

export enum CurrencyEnum {
  Eur = 'EUR',
  Gbp = 'GBP',
  Usd = 'USD',
}

export enum DataCollectionRequestEventEnum {
  Accepted = 'ACCEPTED',
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  DataStaged = 'DATA_STAGED',
  Declined = 'DECLINED',
  Rejected = 'REJECTED',
  Reminded = 'REMINDED',
  Requested = 'REQUESTED',
  Submitted = 'SUBMITTED',
}

export type FilterValues = {
  __typename?: 'FilterValues';
  count: Scalars['Float']['output'];
  display: Scalars['String']['output'];
  group_value?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

export type Geography = {
  __typename?: 'Geography';
  coordinates: Array<Scalars['Float']['output']>;
  type: Scalars['String']['output'];
};

export type GetApiLogs = {
  __typename?: 'GetApiLogs';
  items: Array<ApiLog>;
  last_evaluated_key?: Maybe<Scalars['String']['output']>;
};

export type GetClients = {
  __typename?: 'GetClients';
  count: Scalars['Int']['output'];
  items: Array<Client>;
};

export type GetInventory = {
  __typename?: 'GetInventory';
  items: Array<Inventory>;
  total_count: Scalars['Int']['output'];
};

export type GetInventoryEvents = {
  __typename?: 'GetInventoryEvents';
  items: Array<InventoryEvent>;
  total_count: Scalars['Int']['output'];
};

export type GetOrders = {
  __typename?: 'GetOrders';
  items: Array<Order>;
  pagination_limit: Scalars['Int']['output'];
  total_co2e: Scalars['Float']['output'];
  total_count: Scalars['Int']['output'];
};

export type GetOrdersCo2ERange = {
  __typename?: 'GetOrdersCo2ERange';
  histogram: Array<HistogramBucket>;
  max?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
};

export type GetPortfolio = {
  __typename?: 'GetPortfolio';
  keyFacts?: Maybe<PortfolioKeyFact>;
  portfolio: Portfolio;
  projects: Array<Project>;
};

export type GetPortfolios = {
  __typename?: 'GetPortfolios';
  items: Array<PortfolioDataMapper>;
  total_count: Scalars['Int']['output'];
};

export type GetProjects = {
  __typename?: 'GetProjects';
  items: Array<Project>;
  total_count: Scalars['Int']['output'];
};

export type GetRetirementsConfirmed = {
  __typename?: 'GetRetirementsConfirmed';
  items: Array<RetirementConfirmed>;
  total_count: Scalars['Int']['output'];
};

export type HistogramBucket = {
  __typename?: 'HistogramBucket';
  doc_count: Scalars['Float']['output'];
  key: Scalars['Float']['output'];
};

export type Inventory = {
  __typename?: 'Inventory';
  allocated_weight_kg: Scalars['Float']['output'];
  currency: CurrencyEnum;
  id: Scalars['ID']['output'];
  price_per_kg: Scalars['Float']['output'];
  price_per_kg_in_gbp: Scalars['Float']['output'];
  project: Project;
  project_id: Scalars['String']['output'];
  registry: Registry;
  registry_id: Scalars['String']['output'];
  retired_weight_kg: Scalars['Float']['output'];
  vintage: Scalars['String']['output'];
  weight_kg: Scalars['Float']['output'];
};

export type InventoryEvent = {
  __typename?: 'InventoryEvent';
  allocated_weight_kg?: Maybe<Scalars['Float']['output']>;
  created_date: Scalars['DateTimeISO']['output'];
  currency?: Maybe<CurrencyEnum>;
  id: Scalars['ID']['output'];
  meta_data?: Maybe<Array<Metadata>>;
  notes?: Maybe<Scalars['String']['output']>;
  pledge_meta_data?: Maybe<Array<Metadata>>;
  portfolio?: Maybe<Portfolio>;
  portfolio_id?: Maybe<Scalars['String']['output']>;
  price_per_kg?: Maybe<Scalars['Float']['output']>;
  price_per_kg_in_gbp?: Maybe<Scalars['Float']['output']>;
  project?: Maybe<Project>;
  project_id?: Maybe<Scalars['String']['output']>;
  projects?: Maybe<Array<ProjectsAllocatedList>>;
  reason?: Maybe<Scalars['String']['output']>;
  registry?: Maybe<Registry>;
  registry_id?: Maybe<Scalars['String']['output']>;
  retired_weight_kg?: Maybe<Scalars['Float']['output']>;
  rrp_max?: Maybe<Scalars['Float']['output']>;
  rrp_min?: Maybe<Scalars['Float']['output']>;
  source: Scalars['String']['output'];
  type: InventoryEventTypeEnum;
  vintage?: Maybe<Scalars['String']['output']>;
  weight_kg?: Maybe<Scalars['Float']['output']>;
};

export enum InventoryEventTypeEnum {
  InventoryAdded = 'INVENTORY_ADDED',
  InventoryAllocated = 'INVENTORY_ALLOCATED',
  InventoryRemoved = 'INVENTORY_REMOVED',
  InventoryRetired = 'INVENTORY_RETIRED',
}

export enum InventoryEventsFilterPropertiesEnum {
  EventTypes = 'EVENT_TYPES',
  EventVintages = 'EVENT_VINTAGES',
  ProjectPublicIds = 'PROJECT_PUBLIC_IDS',
}

export enum InventoryEventsSortedColumnEnum {
  EventCreatedDate = 'EVENT_CREATED_DATE',
  EventType = 'EVENT_TYPE',
  EventVintage = 'EVENT_VINTAGE',
}

export enum InventoryFilterPropertiesEnum {
  EventVintages = 'EVENT_VINTAGES',
  ProjectPublicIds = 'PROJECT_PUBLIC_IDS',
  RegistryPublicIds = 'REGISTRY_PUBLIC_IDS',
}

export type InventoryReconciliationResults = {
  __typename?: 'InventoryReconciliationResults';
  created_date: Scalars['DateTimeISO']['output'];
  error_count: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  recon_external_inventory_file_url?: Maybe<Scalars['String']['output']>;
  recon_internal_inventory_file_url?: Maybe<Scalars['String']['output']>;
  recon_results_summary_file_url?: Maybe<Scalars['String']['output']>;
  registry: Registry;
};

export enum InventorySortedColumnEnum {
  ProjectName = 'PROJECT_NAME',
  ProjectVintage = 'PROJECT_VINTAGE',
  RetiredWeight = 'RETIRED_WEIGHT',
  TotalWeight = 'TOTAL_WEIGHT',
  UnallocatedWeight = 'UNALLOCATED_WEIGHT',
}

export type LinkImportToRequestInput = {
  activityClientUserId: Scalars['String']['input'];
  activityNote?: InputMaybe<Scalars['String']['input']>;
  dataCollectionRequestPublicId: Scalars['String']['input'];
  importPublicId: Scalars['String']['input'];
};

export type LocationInput = {
  coordinates: Array<Scalars['Float']['input']>;
};

export type Metadata = {
  __typename?: 'Metadata';
  key: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  add_inventory: Scalars['Boolean']['output'];
  configure_supplier_entitlements_for_data_collection: Scalars['Boolean']['output'];
  confirm_retirement: Scalars['Boolean']['output'];
  create_cargowise_eadaptor_outbound_config: CargoWiseEAdaptorOutboundConfig;
  create_client: Client;
  create_portfolio: Scalars['String']['output'];
  delete_cargowise_eadaptor_outbound_config: Scalars['Boolean']['output'];
  delete_client_user: Scalars['String']['output'];
  link_import_to_request: Scalars['Boolean']['output'];
  migrate_clients_to_chargebee: Scalars['Boolean']['output'];
  publish_portfolio: Scalars['Boolean']['output'];
  reconcile_inventory: Scalars['Boolean']['output'];
  remove_inventory: Scalars['Boolean']['output'];
  roll_api_key: ApiKey;
  set_cargowise_eadaptor_outbound_config_password: CargoWiseEAdaptorOutboundConfig;
  sync_portfolios_in_chargebee: Scalars['Boolean']['output'];
  transfer_ownership: Scalars['String']['output'];
  transition_data_collection_request: Scalars['Boolean']['output'];
  trigger_netlify_deploy: Scalars['Boolean']['output'];
  unpublish_portfolio: Scalars['Boolean']['output'];
  update_cargowise_eadaptor_outbound_config: CargoWiseEAdaptorOutboundConfig;
  update_client: Client;
  upload_file: S3Object;
  upsert_client_integration: ClientIntegration;
  upsert_portfolio_draft: PortfolioDraft;
  upsert_project: Project;
  upsert_project_certification: ProjectCertification;
  upsert_project_developer: ProjectDeveloper;
  upsert_project_owner: ProjectOwner;
  upsert_project_type: ProjectType;
  upsert_registry: Registry;
};

export type MutationAdd_InventoryArgs = {
  data: AddInventoryInput;
};

export type MutationConfigure_Supplier_Entitlements_For_Data_CollectionArgs = {
  data: ConfigureSupplierEntitlementsForDataCollectionInput;
};

export type MutationConfirm_RetirementArgs = {
  data: ConfirmRetirementInput;
};

export type MutationCreate_Cargowise_Eadaptor_Outbound_ConfigArgs = {
  client_integration_id: Scalars['String']['input'];
  password: Scalars['String']['input'];
  sender_id: Scalars['String']['input'];
};

export type MutationCreate_ClientArgs = {
  data: CreateClientInput;
};

export type MutationCreate_PortfolioArgs = {
  data: PortfolioDraftInput;
};

export type MutationDelete_Cargowise_Eadaptor_Outbound_ConfigArgs = {
  id: Scalars['String']['input'];
};

export type MutationDelete_Client_UserArgs = {
  id: Scalars['String']['input'];
};

export type MutationLink_Import_To_RequestArgs = {
  data: LinkImportToRequestInput;
};

export type MutationPublish_PortfolioArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemove_InventoryArgs = {
  data: RemoveInventoryInput;
};

export type MutationRoll_Api_KeyArgs = {
  client_id: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type MutationSet_Cargowise_Eadaptor_Outbound_Config_PasswordArgs = {
  id: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type MutationTransfer_OwnershipArgs = {
  new_owner_client_user_id: Scalars['String']['input'];
};

export type MutationTransition_Data_Collection_RequestArgs = {
  data: TransitionDataCollectionRequestInput;
};

export type MutationUnpublish_PortfolioArgs = {
  id: Scalars['String']['input'];
};

export type MutationUpdate_Cargowise_Eadaptor_Outbound_ConfigArgs = {
  client_integration_id?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  sender_id: Scalars['String']['input'];
};

export type MutationUpdate_ClientArgs = {
  data: UpdateClientInput;
  id: Scalars['String']['input'];
};

export type MutationUpload_FileArgs = {
  file: Scalars['Upload']['input'];
  folder: S3FolderEnum;
};

export type MutationUpsert_Client_IntegrationArgs = {
  data: ClientIntegrationInput;
};

export type MutationUpsert_Portfolio_DraftArgs = {
  data: PortfolioDraftInput;
};

export type MutationUpsert_ProjectArgs = {
  data: UpsertProjectInput;
};

export type MutationUpsert_Project_CertificationArgs = {
  data: UpsertProjectCertificationInput;
};

export type MutationUpsert_Project_DeveloperArgs = {
  data: UpsertDeveloperInput;
};

export type MutationUpsert_Project_OwnerArgs = {
  data: ProjectOwnerInput;
};

export type MutationUpsert_Project_TypeArgs = {
  data: ProjectTypeInput;
};

export type MutationUpsert_RegistryArgs = {
  data: RegistryInput;
};

export type Order = {
  __typename?: 'Order';
  amount: Scalars['Float']['output'];
  client: Client;
  client_user?: Maybe<ClientUser>;
  created_date: Scalars['DateTimeISO']['output'];
  currency: Scalars['String']['output'];
  portfolio: Portfolio;
  public_id: Scalars['String']['output'];
  status: OrderStatusEnum;
  updated_date: Scalars['DateTimeISO']['output'];
  weight_kg: Scalars['Float']['output'];
};

export enum OrderDirectionEnum {
  Ascend = 'ascend',
  Descend = 'descend',
}

export enum OrderFilterPropertiesEnum {
  ClientLegalName = 'CLIENT_LEGAL_NAME',
  ClientPublicId = 'CLIENT_PUBLIC_ID',
  Co2E = 'CO2E',
  InvoiceNumber = 'INVOICE_NUMBER',
  PortfolioName = 'PORTFOLIO_NAME',
  PortfolioPublicId = 'PORTFOLIO_PUBLIC_ID',
  PublicId = 'PUBLIC_ID',
  Status = 'STATUS',
}

export enum OrderSortedColumnEnum {
  Amount = 'AMOUNT',
  CreatedDate = 'CREATED_DATE',
  WeightKg = 'WEIGHT_KG',
}

export enum OrderStatusEnum {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Expired = 'EXPIRED',
  Invoiced = 'INVOICED',
  Paid = 'PAID',
  Placed = 'PLACED',
  Processing = 'PROCESSING',
  Quoted = 'QUOTED',
  Rejected = 'REJECTED',
}

export type Portfolio = {
  __typename?: 'Portfolio';
  allocated_weight_kg: Scalars['Float']['output'];
  allocations: Array<PortfolioAllocation>;
  clients?: Maybe<Array<Client>>;
  created_date: Scalars['DateTimeISO']['output'];
  description: Scalars['String']['output'];
  display_name: Scalars['String']['output'];
  distribution?: Maybe<PortfolioDistribution>;
  id: Scalars['String']['output'];
  is_published: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  portfolio_version?: Maybe<Scalars['String']['output']>;
  prices: Array<PortfolioPrice>;
  public_id: Scalars['String']['output'];
  reserved_weight_kg: Scalars['Float']['output'];
  updated_date: Scalars['DateTimeISO']['output'];
};

export type PortfolioAllocation = {
  __typename?: 'PortfolioAllocation';
  created_date: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
  percentage: Scalars['Float']['output'];
  portfolio: Portfolio;
  project: Project;
  updated_date: Scalars['DateTimeISO']['output'];
};

export type PortfolioDataMapper = {
  __typename?: 'PortfolioDataMapper';
  allocated_weight_kg?: Maybe<Scalars['Float']['output']>;
  created_date: Scalars['DateTimeISO']['output'];
  display_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  is_draft?: Maybe<Scalars['Boolean']['output']>;
  is_published?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  portfolio_version?: Maybe<Scalars['String']['output']>;
  prices?: Maybe<Array<PortfolioPrice>>;
  public_id?: Maybe<Scalars['String']['output']>;
  reserved_weight_kg?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<PortfolioStatusEnum>;
};

export type PortfolioDetailsAllocationsInputType = {
  avoidance: Scalars['Boolean']['input'];
  price_per_kg_in_gbp: Scalars['Float']['input'];
  project_id: Scalars['String']['input'];
  project_name: Scalars['String']['input'];
  registry: Scalars['String']['input'];
  registry_fee_per_tonne: Scalars['Float']['input'];
  registry_id: Scalars['String']['input'];
  removal: Scalars['Boolean']['input'];
  vintage: Scalars['String']['input'];
  weight_tonnes: Scalars['Float']['input'];
};

export type PortfolioDetailsAllocationsType = {
  __typename?: 'PortfolioDetailsAllocationsType';
  avoidance: Scalars['Boolean']['output'];
  price_per_kg_in_gbp: Scalars['Float']['output'];
  project_id: Scalars['String']['output'];
  project_name: Scalars['String']['output'];
  registry: Scalars['String']['output'];
  registry_fee_per_tonne: Scalars['Float']['output'];
  registry_id: Scalars['String']['output'];
  removal: Scalars['Boolean']['output'];
  vintage: Scalars['String']['output'];
  weight_tonnes: Scalars['Float']['output'];
};

export type PortfolioDistribution = {
  __typename?: 'PortfolioDistribution';
  avoidance: Scalars['Float']['output'];
  removal: Scalars['Float']['output'];
  totalAllocated: Scalars['Float']['output'];
};

export type PortfolioDraft = {
  __typename?: 'PortfolioDraft';
  created_date: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
  portfolio_draft: PortfolioDraftJsonType;
  updated_date: Scalars['DateTimeISO']['output'];
};

export type PortfolioDraftDetailsInputType = {
  accounting_code?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<CurrencyEnum>;
  description?: InputMaybe<Scalars['String']['input']>;
  key_facts?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  portfolio_version?: InputMaybe<Scalars['String']['input']>;
};

export type PortfolioDraftDetailsType = {
  __typename?: 'PortfolioDraftDetailsType';
  accounting_code?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<CurrencyEnum>;
  description?: Maybe<Scalars['String']['output']>;
  key_facts?: Maybe<Array<Scalars['String']['output']>>;
  name?: Maybe<Scalars['String']['output']>;
  portfolio_version?: Maybe<Scalars['String']['output']>;
};

export type PortfolioDraftInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  portfolio_draft: PortfolioDraftJsonInputType;
};

export type PortfolioDraftJsonInputType = {
  acl?: InputMaybe<Array<Scalars['String']['input']>>;
  allocations?: InputMaybe<Array<PortfolioDetailsAllocationsInputType>>;
  details?: InputMaybe<PortfolioDraftDetailsInputType>;
};

export type PortfolioDraftJsonType = {
  __typename?: 'PortfolioDraftJsonType';
  acl?: Maybe<Array<Scalars['String']['output']>>;
  allocations?: Maybe<Array<PortfolioDetailsAllocationsType>>;
  details?: Maybe<PortfolioDraftDetailsType>;
};

export type PortfolioKeyFact = {
  __typename?: 'PortfolioKeyFact';
  id: Scalars['String']['output'];
  key_facts: Array<Scalars['String']['output']>;
};

export enum PortfolioOrderColumnEnum {
  CreatedDate = 'CREATED_DATE',
  Name = 'NAME',
}

export type PortfolioPrice = {
  __typename?: 'PortfolioPrice';
  currency: Scalars['String']['output'];
  price_per_kg: Scalars['Float']['output'];
};

export type PortfolioStats = {
  __typename?: 'PortfolioStats';
  total_orders: Scalars['Float']['output'];
};

export enum PortfolioStatusEnum {
  Confirmed = 'CONFIRMED',
  Created = 'CREATED',
}

export type Project = {
  __typename?: 'Project';
  avoidance: Scalars['Boolean']['output'];
  carbonplan_assessment?: Maybe<CarbonplanAssessment>;
  certifications?: Maybe<Array<ProjectCertification>>;
  contact?: Maybe<Scalars['String']['output']>;
  coordinates?: Maybe<Geography>;
  country: Country;
  created_date: Scalars['DateTimeISO']['output'];
  description?: Maybe<Scalars['String']['output']>;
  developer?: Maybe<ProjectDeveloper>;
  id: Scalars['String']['output'];
  location?: Maybe<Scalars['String']['output']>;
  media?: Maybe<Array<S3Object>>;
  name: Scalars['String']['output'];
  owner?: Maybe<ProjectOwner>;
  project_type: ProjectType;
  public_id: Scalars['String']['output'];
  registry?: Maybe<Registry>;
  registry_project_id?: Maybe<Scalars['String']['output']>;
  registry_project_url?: Maybe<Scalars['String']['output']>;
  removal: Scalars['Boolean']['output'];
  sdgs?: Maybe<Array<SustainableDevelopmentGoalsEnum>>;
  updated_date: Scalars['DateTimeISO']['output'];
  website?: Maybe<Scalars['String']['output']>;
};

export type ProjectCertification = {
  __typename?: 'ProjectCertification';
  created_date: Scalars['DateTimeISO']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  media?: Maybe<Array<S3Object>>;
  name: Scalars['String']['output'];
  registry_link: Scalars['String']['output'];
  updated_date: Scalars['DateTimeISO']['output'];
};

export type ProjectDeveloper = {
  __typename?: 'ProjectDeveloper';
  created_date: Scalars['DateTimeISO']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  media?: Maybe<Array<S3Object>>;
  name: Scalars['String']['output'];
  projects: Array<Project>;
  public_id: Scalars['String']['output'];
  updated_date: Scalars['DateTimeISO']['output'];
  website?: Maybe<Scalars['String']['output']>;
};

export enum ProjectFilterPropertiesEnum {
  ProjectPublicIds = 'PROJECT_PUBLIC_IDS',
}

export type ProjectOwner = {
  __typename?: 'ProjectOwner';
  created_date: Scalars['DateTimeISO']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updated_date: Scalars['DateTimeISO']['output'];
};

export type ProjectOwnerInput = {
  description: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export enum ProjectSortedColumnEnum {
  Name = 'NAME',
}

export type ProjectType = {
  __typename?: 'ProjectType';
  category: Scalars['String']['output'];
  created_date: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updated_date: Scalars['DateTimeISO']['output'];
};

export type ProjectTypeInput = {
  category: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export type ProjectsAllocatedList = {
  __typename?: 'ProjectsAllocatedList';
  project_id: Scalars['String']['output'];
  registry: Scalars['String']['output'];
  registry_id: Scalars['String']['output'];
  vintage: Scalars['String']['output'];
  weight_kg: Scalars['Float']['output'];
};

export type Query = {
  __typename?: 'Query';
  api_keys: Array<ApiKey>;
  api_logs: GetApiLogs;
  available_inventory: Array<Inventory>;
  client: Client;
  client_integration: ClientIntegration;
  client_integrations: Array<ClientIntegration>;
  clients: GetClients;
  countries: Array<Country>;
  inventory: GetInventory;
  inventoryReconciliationResults: Array<InventoryReconciliationResults>;
  inventory_event: InventoryEvent;
  inventory_events: GetInventoryEvents;
  inventory_events_property_values: Array<FilterValues>;
  inventory_property_values: Array<FilterValues>;
  order_co2e_range: GetOrdersCo2ERange;
  order_metadata_keys: Array<Scalars['String']['output']>;
  order_metadata_values: Array<FilterValues>;
  order_property_values: Array<FilterValues>;
  orders: GetOrders;
  portfolio: GetPortfolio;
  portfolio_draft: PortfolioDraft;
  portfolio_stats: PortfolioStats;
  portfolios: GetPortfolios;
  project: Project;
  project_certification: ProjectCertification;
  project_certifications: Array<ProjectCertification>;
  project_developer: ProjectDeveloper;
  project_developers: Array<ProjectDeveloper>;
  project_owner: ProjectOwner;
  project_owners: Array<ProjectOwner>;
  project_property_values: Array<FilterValues>;
  project_type: ProjectType;
  project_type_categories: Array<ProjectType>;
  project_types: Array<ProjectType>;
  projects: GetProjects;
  registries: Array<Registry>;
  registry: Registry;
  retirements_confirmed: GetRetirementsConfirmed;
  retirements_requested: Array<RetirementRequested>;
  user: User;
};

export type QueryApi_KeysArgs = {
  client_id: Scalars['String']['input'];
};

export type QueryApi_LogsArgs = {
  client_id: Scalars['String']['input'];
  descend?: InputMaybe<Scalars['Boolean']['input']>;
  exclusive_start_key?: InputMaybe<Scalars['String']['input']>;
};

export type QueryClientArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  public_id?: InputMaybe<Scalars['String']['input']>;
};

export type QueryClient_IntegrationArgs = {
  public_id: Scalars['String']['input'];
};

export type QueryClient_IntegrationsArgs = {
  client_id: Scalars['String']['input'];
};

export type QueryClientsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryInventoryArgs = {
  page: Scalars['Int']['input'];
  project_public_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  registry_public_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  size: Scalars['Int']['input'];
  sorted_column?: InputMaybe<InventorySortedColumnEnum>;
  sorted_order?: InputMaybe<SortedOrderEnum>;
  vintages?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryInventory_EventArgs = {
  id: Scalars['String']['input'];
};

export type QueryInventory_EventsArgs = {
  event_types?: InputMaybe<Array<InventoryEventTypeEnum>>;
  page: Scalars['Int']['input'];
  project_public_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  size: Scalars['Int']['input'];
  sorted_column?: InputMaybe<InventoryEventsSortedColumnEnum>;
  sorted_order?: InputMaybe<SortedOrderEnum>;
  vintages?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryInventory_Events_Property_ValuesArgs = {
  key: InventoryEventsFilterPropertiesEnum;
  project_public_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  value: Array<Scalars['String']['input']>;
};

export type QueryInventory_Property_ValuesArgs = {
  key: InventoryFilterPropertiesEnum;
  value: Array<Scalars['String']['input']>;
};

export type QueryOrder_Metadata_ValuesArgs = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type QueryOrder_Property_ValuesArgs = {
  key: OrderFilterPropertiesEnum;
  value: Scalars['String']['input'];
};

export type QueryOrdersArgs = {
  client_legal_names?: InputMaybe<Array<Scalars['String']['input']>>;
  client_public_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  co2e?: InputMaybe<RangeInput>;
  invoice_numbers?: InputMaybe<Array<Scalars['String']['input']>>;
  page: Scalars['Int']['input'];
  portfolio_names?: InputMaybe<Array<Scalars['String']['input']>>;
  portfolio_public_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  public_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  size: Scalars['Int']['input'];
  sorted_column?: InputMaybe<OrderSortedColumnEnum>;
  sorted_order?: InputMaybe<SortedOrderEnum>;
  status?: InputMaybe<Array<OrderStatusEnum>>;
};

export type QueryPortfolioArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  public_id?: InputMaybe<Scalars['String']['input']>;
};

export type QueryPortfolio_DraftArgs = {
  id: Scalars['String']['input'];
};

export type QueryPortfolio_StatsArgs = {
  id: Scalars['String']['input'];
};

export type QueryPortfoliosArgs = {
  order_column?: InputMaybe<PortfolioOrderColumnEnum>;
  order_direction?: InputMaybe<OrderDirectionEnum>;
  page?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryProjectArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  public_id?: InputMaybe<Scalars['String']['input']>;
};

export type QueryProject_CertificationArgs = {
  id: Scalars['String']['input'];
};

export type QueryProject_DeveloperArgs = {
  public_id: Scalars['String']['input'];
};

export type QueryProject_OwnerArgs = {
  id: Scalars['String']['input'];
};

export type QueryProject_Property_ValuesArgs = {
  key: ProjectFilterPropertiesEnum;
  value: Array<Scalars['String']['input']>;
};

export type QueryProject_TypeArgs = {
  id: Scalars['String']['input'];
};

export type QueryProjectsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  project_name?: InputMaybe<Scalars['String']['input']>;
  project_public_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  project_types?: InputMaybe<Array<Scalars['ID']['input']>>;
  size?: InputMaybe<Scalars['Int']['input']>;
  sorted_column?: InputMaybe<ProjectSortedColumnEnum>;
  sorted_order?: InputMaybe<SortedOrderEnum>;
};

export type QueryRegistryArgs = {
  public_id: Scalars['String']['input'];
};

export type QueryRetirements_ConfirmedArgs = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  page: Scalars['Int']['input'];
  project_names?: InputMaybe<Array<Scalars['String']['input']>>;
  project_public_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  registry_names?: InputMaybe<Array<Scalars['String']['input']>>;
  registry_public_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  size: Scalars['Int']['input'];
  sorted_column?: InputMaybe<RetirementSortedColumnEnum>;
  sorted_order?: InputMaybe<SortedOrderEnum>;
};

export type RangeInput = {
  max: Scalars['Float']['input'];
  min: Scalars['Float']['input'];
};

export type Registry = {
  __typename?: 'Registry';
  created_date: Scalars['DateTimeISO']['output'];
  has_automated_retirement?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  infrastructure?: Maybe<Scalars['String']['output']>;
  media?: Maybe<Array<S3Object>>;
  name: Scalars['String']['output'];
  name_short?: Maybe<Scalars['String']['output']>;
  projects_base_url?: Maybe<Scalars['String']['output']>;
  public_id: Scalars['String']['output'];
  registry_retirement_fee_per_tonne?: Maybe<Scalars['Float']['output']>;
  updated_date: Scalars['DateTimeISO']['output'];
  website?: Maybe<Scalars['String']['output']>;
};

export type RegistryInput = {
  has_automated_retirement?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  infrastructure?: InputMaybe<Scalars['String']['input']>;
  media?: InputMaybe<Array<S3ObjectInput>>;
  name: Scalars['String']['input'];
  name_short?: InputMaybe<Scalars['String']['input']>;
  projects_base_url?: InputMaybe<Scalars['String']['input']>;
  registry_retirement_fee_per_tonne?: InputMaybe<Scalars['Float']['input']>;
  website: Scalars['String']['input'];
};

export type RemoveInventoryInput = {
  notes: Scalars['String']['input'];
  price_per_tonne?: InputMaybe<Scalars['Float']['input']>;
  price_per_tonne_in_gbp?: InputMaybe<Scalars['Float']['input']>;
  project_id: Scalars['String']['input'];
  reason: Scalars['String']['input'];
  registry?: InputMaybe<Scalars['String']['input']>;
  registry_id?: InputMaybe<Scalars['String']['input']>;
  vintage: Scalars['String']['input'];
  weight_tonnes: Scalars['Float']['input'];
};

export type RetirementConfirmed = {
  __typename?: 'RetirementConfirmed';
  created_date: Scalars['DateTimeISO']['output'];
  portfolio: Portfolio;
  project: Project;
  registry?: Maybe<Registry>;
  retirement_id: Scalars['ID']['output'];
  retirement_url: Scalars['String']['output'];
  serial_numbers: Array<Scalars['String']['output']>;
  vintage: Scalars['String']['output'];
  weight_kg: Scalars['Int']['output'];
};

export type RetirementRequested = {
  __typename?: 'RetirementRequested';
  created_date: Scalars['DateTimeISO']['output'];
  project: Project;
  registry?: Maybe<Registry>;
  retirement_id: Scalars['ID']['output'];
  vintage: Scalars['String']['output'];
  weight_kg: Scalars['Int']['output'];
};

export enum RetirementSortedColumnEnum {
  CreatedDate = 'CREATED_DATE',
  ProjectName = 'PROJECT_NAME',
  RegistryName = 'REGISTRY_NAME',
  Vintage = 'VINTAGE',
  WeightKg = 'WEIGHT_KG',
}

export enum S3FolderEnum {
  Project = 'PROJECT',
  ProjectCertification = 'PROJECT_CERTIFICATION',
  ProjectDeveloper = 'PROJECT_DEVELOPER',
  Provider = 'PROVIDER',
  Registry = 'REGISTRY',
}

export type S3Object = {
  __typename?: 'S3Object';
  bucket: Scalars['String']['output'];
  eTag?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  location?: Maybe<Scalars['String']['output']>;
};

export type S3ObjectInput = {
  bucket: Scalars['String']['input'];
  eTag?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
  location?: InputMaybe<Scalars['String']['input']>;
};

export enum SortedOrderEnum {
  Ascend = 'ascend',
  Descend = 'descend',
}

export enum SustainableDevelopmentGoalsEnum {
  Ace = 'ACE',
  Ca = 'CA',
  Cws = 'CWS',
  Dweg = 'DWEG',
  Ge = 'GE',
  Ghw = 'GHW',
  Iii = 'III',
  Lbw = 'LBW',
  Ll = 'LL',
  Np = 'NP',
  Pg = 'PG',
  Pjsi = 'PJSI',
  Qe = 'QE',
  Rcp = 'RCP',
  Ri = 'RI',
  Scc = 'SCC',
  Zh = 'ZH',
}

export enum TaxIdTypeEnum {
  AeTrn = 'AE_TRN',
  AuAbn = 'AU_ABN',
  BrCnpj = 'BR_CNPJ',
  BrCpf = 'BR_CPF',
  CaBn = 'CA_BN',
  CaGstHst = 'CA_GST_HST',
  CaPstBc = 'CA_PST_BC',
  CaPstMb = 'CA_PST_MB',
  CaPstSk = 'CA_PST_SK',
  CaQst = 'CA_QST',
  ChVat = 'CH_VAT',
  ClTin = 'CL_TIN',
  EsCif = 'ES_CIF',
  EuVat = 'EU_VAT',
  GbVat = 'GB_VAT',
  HkBr = 'HK_BR',
  IdNpwp = 'ID_NPWP',
  IlVat = 'IL_VAT',
  InGst = 'IN_GST',
  JpCn = 'JP_CN',
  JpRn = 'JP_RN',
  KrBrn = 'KR_BRN',
  LiUid = 'LI_UID',
  MxRfc = 'MX_RFC',
  MyFrp = 'MY_FRP',
  MyItn = 'MY_ITN',
  MySst = 'MY_SST',
  NoVat = 'NO_VAT',
  NzGst = 'NZ_GST',
  RuInn = 'RU_INN',
  RuKpp = 'RU_KPP',
  SaVat = 'SA_VAT',
  SgGst = 'SG_GST',
  SgUen = 'SG_UEN',
  ThVat = 'TH_VAT',
  TwVat = 'TW_VAT',
  UsEin = 'US_EIN',
  ZaVat = 'ZA_VAT',
}

export type TransitionDataCollectionRequestInput = {
  activityClientUserId: Scalars['String']['input'];
  activityEvent: DataCollectionRequestEventEnum;
  activityNote?: InputMaybe<Scalars['String']['input']>;
  dataCollectionRequestPublicId: Scalars['String']['input'];
};

export type UpdateClientInput = {
  address_1?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  billing_email_address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  client_function?: InputMaybe<ClientFunctionEnum>;
  country: CountryInput;
  currency?: InputMaybe<CurrencyEnum>;
  legal_name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  zip_code?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertDeveloperInput = {
  description: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  logo?: InputMaybe<S3ObjectInput>;
  name: Scalars['String']['input'];
  website: Scalars['String']['input'];
};

export type UpsertProjectCertificationInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  media?: InputMaybe<Array<S3ObjectInput>>;
  name: Scalars['String']['input'];
  registry_link: Scalars['String']['input'];
};

export type UpsertProjectInput = {
  avoidance: Scalars['Boolean']['input'];
  carbon_plan_id?: InputMaybe<Scalars['String']['input']>;
  contact?: InputMaybe<Scalars['String']['input']>;
  coordinates?: InputMaybe<LocationInput>;
  country_id: Scalars['String']['input'];
  description: Scalars['String']['input'];
  developer_id: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  location: Scalars['String']['input'];
  media?: InputMaybe<Array<S3ObjectInput>>;
  name: Scalars['String']['input'];
  owner_id: Scalars['String']['input'];
  project_certification_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  project_type_id: Scalars['String']['input'];
  registry_id: Scalars['String']['input'];
  registry_project_id?: InputMaybe<Scalars['String']['input']>;
  registry_project_url?: InputMaybe<Scalars['String']['input']>;
  removal: Scalars['Boolean']['input'];
  sdgs?: InputMaybe<Array<SustainableDevelopmentGoalsEnum>>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  email: Scalars['String']['output'];
  email_verified: Scalars['String']['output'];
  name: Scalars['String']['output'];
  sub: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type InventoryReconciliationResults_FromGetInventoryReconciliationResultsFragment =
  {
    __typename?: 'InventoryReconciliationResults';
    id: string;
    created_date: string;
    error_count: number;
    recon_results_summary_file_url?: string | null;
    recon_external_inventory_file_url?: string | null;
    recon_internal_inventory_file_url?: string | null;
  };

export type GetInventoryReconciliationResultsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetInventoryReconciliationResultsQuery = {
  __typename?: 'Query';
  inventoryReconciliationResults: Array<{
    __typename?: 'InventoryReconciliationResults';
    id: string;
    created_date: string;
    error_count: number;
    recon_results_summary_file_url?: string | null;
    recon_external_inventory_file_url?: string | null;
    recon_internal_inventory_file_url?: string | null;
  }>;
};

export type ApiKey_FromGetApiKeysFragment = {
  __typename?: 'ApiKey';
  id: string;
  name: string;
  api_key: string;
  created_date: string;
};

export type GetApiKeysQueryVariables = Exact<{
  client_id: Scalars['String']['input'];
}>;

export type GetApiKeysQuery = {
  __typename?: 'Query';
  api_keys: Array<{
    __typename?: 'ApiKey';
    id: string;
    name: string;
    api_key: string;
    created_date: string;
  }>;
};

export type ApiLog_FromGetApiLogsFragment = {
  __typename?: 'ApiLog';
  id: string;
  date: string;
  api_key: string;
  endpoint: string;
  response_code: number;
  request: string;
  response: string;
};

export type GetApiLogsQueryVariables = Exact<{
  descend?: InputMaybe<Scalars['Boolean']['input']>;
  exclusive_start_key?: InputMaybe<Scalars['String']['input']>;
  client_id: Scalars['String']['input'];
}>;

export type GetApiLogsQuery = {
  __typename?: 'Query';
  api_logs: {
    __typename?: 'GetApiLogs';
    last_evaluated_key?: string | null;
    items: Array<{
      __typename?: 'ApiLog';
      id: string;
      date: string;
      api_key: string;
      endpoint: string;
      response_code: number;
      request: string;
      response: string;
    }>;
  };
};

export type RollApiKeyMutationVariables = Exact<{
  client_id: Scalars['String']['input'];
  id: Scalars['String']['input'];
}>;

export type RollApiKeyMutation = {
  __typename?: 'Mutation';
  roll_api_key: { __typename?: 'ApiKey'; id: string };
};

export type CreateCargoWiseEAdaptorOutboundConfigMutationVariables = Exact<{
  client_integration_id: Scalars['String']['input'];
  sender_id: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;

export type CreateCargoWiseEAdaptorOutboundConfigMutation = {
  __typename?: 'Mutation';
  create_cargowise_eadaptor_outbound_config: {
    __typename?: 'CargoWiseEAdaptorOutboundConfig';
    id: string;
    sender_id: string;
  };
};

export type DeleteCargoWiseEAdaptorOutboundConfigMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type DeleteCargoWiseEAdaptorOutboundConfigMutation = {
  __typename?: 'Mutation';
  delete_cargowise_eadaptor_outbound_config: boolean;
};

export type UpdateCargoWiseEAdaptorOutboundConfigMutationVariables = Exact<{
  id: Scalars['String']['input'];
  sender_id: Scalars['String']['input'];
}>;

export type UpdateCargoWiseEAdaptorOutboundConfigMutation = {
  __typename?: 'Mutation';
  update_cargowise_eadaptor_outbound_config: {
    __typename?: 'CargoWiseEAdaptorOutboundConfig';
    id: string;
    sender_id: string;
  };
};

export type UpdateCargoWiseEAdaptorOutboundConfigPasswordMutationVariables =
  Exact<{
    id: Scalars['String']['input'];
    password: Scalars['String']['input'];
  }>;

export type UpdateCargoWiseEAdaptorOutboundConfigPasswordMutation = {
  __typename?: 'Mutation';
  set_cargowise_eadaptor_outbound_config_password: {
    __typename?: 'CargoWiseEAdaptorOutboundConfig';
    id: string;
    sender_id: string;
  };
};

export type Client_FromCreateClientFragment = {
  __typename?: 'Client';
  id: string;
};

export type CreateClientMutationVariables = Exact<{
  data: CreateClientInput;
}>;

export type CreateClientMutation = {
  __typename?: 'Mutation';
  create_client: { __typename?: 'Client'; id: string };
};

export type DeleteClientUserMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type DeleteClientUserMutation = {
  __typename?: 'Mutation';
  delete_client_user: string;
};

export type ClientSubscriptionEntitlement_FromGetClientFragment = {
  __typename?: 'ClientSubscriptionEntitlement';
  feature_id: string;
  feature_type: string;
  value: string;
  is_enabled: boolean;
};

export type ClientUser_FromGetClientFragment = {
  __typename?: 'ClientUser';
  id: string;
  first_name?: string | null;
  last_name?: string | null;
  email: string;
  account_state: AccountStateEnum;
  created_date: string;
  role: { __typename?: 'ClientRole'; name: ClientRoleEnum };
};

export type Client_FromGetClientFragment = {
  __typename?: 'Client';
  id: string;
  public_id: string;
  legal_name?: string | null;
  address_1?: string | null;
  address_2?: string | null;
  city?: string | null;
  zip_code?: string | null;
  region?: string | null;
  website?: string | null;
  phone?: string | null;
  billing_email_address?: string | null;
  currency?: CurrencyEnum | null;
  client_function?: ClientFunctionEnum | null;
  tax_id_type?: TaxIdTypeEnum | null;
  tax_id_value?: string | null;
  country?: { __typename?: 'Country'; code: string; name: string } | null;
  users: Array<{
    __typename?: 'ClientUser';
    id: string;
    first_name?: string | null;
    last_name?: string | null;
    email: string;
    account_state: AccountStateEnum;
    created_date: string;
    role: { __typename?: 'ClientRole'; name: ClientRoleEnum };
  }>;
  domains: Array<{ __typename?: 'ClientDomain'; domain: string }>;
  entitlements: Array<{
    __typename?: 'ClientSubscriptionEntitlement';
    feature_id: string;
    feature_type: string;
    value: string;
    is_enabled: boolean;
  }>;
};

export type GetClientQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  publicId?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetClientQuery = {
  __typename?: 'Query';
  client: {
    __typename?: 'Client';
    id: string;
    public_id: string;
    legal_name?: string | null;
    address_1?: string | null;
    address_2?: string | null;
    city?: string | null;
    zip_code?: string | null;
    region?: string | null;
    website?: string | null;
    phone?: string | null;
    billing_email_address?: string | null;
    currency?: CurrencyEnum | null;
    client_function?: ClientFunctionEnum | null;
    tax_id_type?: TaxIdTypeEnum | null;
    tax_id_value?: string | null;
    country?: { __typename?: 'Country'; code: string; name: string } | null;
    users: Array<{
      __typename?: 'ClientUser';
      id: string;
      first_name?: string | null;
      last_name?: string | null;
      email: string;
      account_state: AccountStateEnum;
      created_date: string;
      role: { __typename?: 'ClientRole'; name: ClientRoleEnum };
    }>;
    domains: Array<{ __typename?: 'ClientDomain'; domain: string }>;
    entitlements: Array<{
      __typename?: 'ClientSubscriptionEntitlement';
      feature_id: string;
      feature_type: string;
      value: string;
      is_enabled: boolean;
    }>;
  };
};

export type ClientUser_FromGetClientIntegrationFragment = {
  __typename?: 'ClientUser';
  id: string;
  first_name?: string | null;
  last_name?: string | null;
  email: string;
};

export type Client_FromGetClientIntegrationFragment = {
  __typename?: 'Client';
  id: string;
  users: Array<{
    __typename?: 'ClientUser';
    id: string;
    first_name?: string | null;
    last_name?: string | null;
    email: string;
  }>;
};

export type GetClientIntegrationQueryVariables = Exact<{
  public_id: Scalars['String']['input'];
}>;

export type GetClientIntegrationQuery = {
  __typename?: 'Query';
  client_integration: {
    __typename?: 'ClientIntegration';
    id: string;
    public_id: string;
    name: string;
    system: ClientIntegrationSystemEnum;
    secondary_system?: ClientIntegrationSecondarySystemEnum | null;
    test: boolean;
    status: ClientIntegrationStatusEnum;
    status_update_date: string;
    type?: ClientIntegrationConfigurationTypeEnum | null;
    configuration_stringified?: string | null;
    status_update_requested_by?: {
      __typename?: 'ClientUser';
      id: string;
      first_name?: string | null;
      last_name?: string | null;
      email: string;
    } | null;
    cargowise_eadaptor_outbound_configs: Array<{
      __typename?: 'CargoWiseEAdaptorOutboundConfig';
      id: string;
      sender_id: string;
    }>;
  };
};

export type CargoWiseEAdaptorOutboundConfig_FromGetClientIntegrationsFragment =
  {
    __typename?: 'CargoWiseEAdaptorOutboundConfig';
    id: string;
    sender_id: string;
  };

export type ClientIntegration_FromGetClientIntegrationsFragment = {
  __typename?: 'ClientIntegration';
  id: string;
  public_id: string;
  name: string;
  system: ClientIntegrationSystemEnum;
  secondary_system?: ClientIntegrationSecondarySystemEnum | null;
  test: boolean;
  status: ClientIntegrationStatusEnum;
  status_update_date: string;
  type?: ClientIntegrationConfigurationTypeEnum | null;
  configuration_stringified?: string | null;
  status_update_requested_by?: {
    __typename?: 'ClientUser';
    id: string;
    first_name?: string | null;
    last_name?: string | null;
    email: string;
  } | null;
  cargowise_eadaptor_outbound_configs: Array<{
    __typename?: 'CargoWiseEAdaptorOutboundConfig';
    id: string;
    sender_id: string;
  }>;
};

export type GetClientIntegrationsQueryVariables = Exact<{
  client_id: Scalars['String']['input'];
}>;

export type GetClientIntegrationsQuery = {
  __typename?: 'Query';
  client_integrations: Array<{
    __typename?: 'ClientIntegration';
    id: string;
    public_id: string;
    name: string;
    system: ClientIntegrationSystemEnum;
    secondary_system?: ClientIntegrationSecondarySystemEnum | null;
    test: boolean;
    status: ClientIntegrationStatusEnum;
    status_update_date: string;
    type?: ClientIntegrationConfigurationTypeEnum | null;
    configuration_stringified?: string | null;
    status_update_requested_by?: {
      __typename?: 'ClientUser';
      id: string;
      first_name?: string | null;
      last_name?: string | null;
      email: string;
    } | null;
    cargowise_eadaptor_outbound_configs: Array<{
      __typename?: 'CargoWiseEAdaptorOutboundConfig';
      id: string;
      sender_id: string;
    }>;
  }>;
};

export type Client_FromGetClientsFragment = {
  __typename?: 'Client';
  id: string;
  public_id: string;
  legal_name?: string | null;
  address_1?: string | null;
  address_2?: string | null;
  zip_code?: string | null;
  city?: string | null;
  region?: string | null;
  website?: string | null;
  phone?: string | null;
  country?: { __typename?: 'Country'; code: string; name: string } | null;
  users: Array<{
    __typename?: 'ClientUser';
    id: string;
    first_name?: string | null;
    last_name?: string | null;
    email: string;
    account_state: AccountStateEnum;
  }>;
};

export type GetClientsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetClientsQuery = {
  __typename?: 'Query';
  clients: {
    __typename?: 'GetClients';
    count: number;
    items: Array<{
      __typename?: 'Client';
      id: string;
      public_id: string;
      legal_name?: string | null;
      address_1?: string | null;
      address_2?: string | null;
      zip_code?: string | null;
      city?: string | null;
      region?: string | null;
      website?: string | null;
      phone?: string | null;
      country?: { __typename?: 'Country'; code: string; name: string } | null;
      users: Array<{
        __typename?: 'ClientUser';
        id: string;
        first_name?: string | null;
        last_name?: string | null;
        email: string;
        account_state: AccountStateEnum;
      }>;
    }>;
  };
};

export type Client_FromGetClientsIdNameFragment = {
  __typename?: 'Client';
  id: string;
  legal_name?: string | null;
};

export type GetClientsIdNameQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetClientsIdNameQuery = {
  __typename?: 'Query';
  clients: {
    __typename?: 'GetClients';
    count: number;
    items: Array<{
      __typename?: 'Client';
      id: string;
      legal_name?: string | null;
    }>;
  };
};

export type TransferClientOwnershipMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type TransferClientOwnershipMutation = {
  __typename?: 'Mutation';
  transfer_ownership: string;
};

export type Client_FromUpdateClientFragment = {
  __typename?: 'Client';
  id: string;
};

export type UpdateClientMutationVariables = Exact<{
  id: Scalars['String']['input'];
  data: UpdateClientInput;
}>;

export type UpdateClientMutation = {
  __typename?: 'Mutation';
  update_client: { __typename?: 'Client'; id: string };
};

export type ClientIntegration_FromUpsertClientIntegrationFragment = {
  __typename?: 'ClientIntegration';
  id: string;
};

export type UpsertClientIntegrationMutationVariables = Exact<{
  data: ClientIntegrationInput;
}>;

export type UpsertClientIntegrationMutation = {
  __typename?: 'Mutation';
  upsert_client_integration: { __typename?: 'ClientIntegration'; id: string };
};

export type UploadFileMutationVariables = Exact<{
  file: Scalars['Upload']['input'];
  folder: S3FolderEnum;
}>;

export type UploadFileMutation = {
  __typename?: 'Mutation';
  upload_file: {
    __typename?: 'S3Object';
    location?: string | null;
    eTag?: string | null;
    bucket: string;
    key: string;
  };
};

export type Country_FromGetCountriesFragment = {
  __typename?: 'Country';
  id: string;
  name: string;
  code: string;
};

export type GetCountriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCountriesQuery = {
  __typename?: 'Query';
  countries: Array<{
    __typename?: 'Country';
    id: string;
    name: string;
    code: string;
  }>;
};

export type ConfigureSupplierEntitlementsForDataCollectionMutationVariables =
  Exact<{
    data: ConfigureSupplierEntitlementsForDataCollectionInput;
  }>;

export type ConfigureSupplierEntitlementsForDataCollectionMutation = {
  __typename?: 'Mutation';
  configure_supplier_entitlements_for_data_collection: boolean;
};

export type LinkImportToRequestMutationVariables = Exact<{
  data: LinkImportToRequestInput;
}>;

export type LinkImportToRequestMutation = {
  __typename?: 'Mutation';
  link_import_to_request: boolean;
};

export type TransitionDataCollectionRequestMutationVariables = Exact<{
  data: TransitionDataCollectionRequestInput;
}>;

export type TransitionDataCollectionRequestMutation = {
  __typename?: 'Mutation';
  transition_data_collection_request: boolean;
};

export type AddInventoryMutationVariables = Exact<{
  data: AddInventoryInput;
}>;

export type AddInventoryMutation = {
  __typename?: 'Mutation';
  add_inventory: boolean;
};

export type Inventory_FromGetAvailableInventoryFragment = {
  __typename?: 'Inventory';
  id: string;
  allocated_weight_kg: number;
  weight_kg: number;
  price_per_kg_in_gbp: number;
  vintage: string;
  project: {
    __typename?: 'Project';
    name: string;
    id: string;
    avoidance: boolean;
    removal: boolean;
    media?: Array<{
      __typename?: 'S3Object';
      key: string;
      bucket: string;
    }> | null;
    registry?: {
      __typename?: 'Registry';
      registry_retirement_fee_per_tonne?: number | null;
    } | null;
  };
  registry: {
    __typename?: 'Registry';
    id: string;
    name: string;
    public_id: string;
  };
};

export type GetAvailableInventoryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAvailableInventoryQuery = {
  __typename?: 'Query';
  available_inventory: Array<{
    __typename?: 'Inventory';
    id: string;
    allocated_weight_kg: number;
    weight_kg: number;
    price_per_kg_in_gbp: number;
    vintage: string;
    project: {
      __typename?: 'Project';
      name: string;
      id: string;
      avoidance: boolean;
      removal: boolean;
      media?: Array<{
        __typename?: 'S3Object';
        key: string;
        bucket: string;
      }> | null;
      registry?: {
        __typename?: 'Registry';
        registry_retirement_fee_per_tonne?: number | null;
      } | null;
    };
    registry: {
      __typename?: 'Registry';
      id: string;
      name: string;
      public_id: string;
    };
  }>;
};

export type Inventory_FromGetInventoryFragment = {
  __typename?: 'Inventory';
  id: string;
  vintage: string;
  weight_kg: number;
  allocated_weight_kg: number;
  retired_weight_kg: number;
  price_per_kg: number;
  price_per_kg_in_gbp: number;
  currency: CurrencyEnum;
  project: {
    __typename?: 'Project';
    name: string;
    id: string;
    public_id: string;
  };
  registry: {
    __typename?: 'Registry';
    id: string;
    name: string;
    public_id: string;
  };
};

export type GetInventoryQueryVariables = Exact<{
  page: Scalars['Int']['input'];
  size: Scalars['Int']['input'];
  vintages?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  projectPublicIds?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  registryPublicIds?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  sortedColumn?: InputMaybe<InventorySortedColumnEnum>;
  sortedOrder?: InputMaybe<SortedOrderEnum>;
}>;

export type GetInventoryQuery = {
  __typename?: 'Query';
  inventory: {
    __typename?: 'GetInventory';
    total_count: number;
    items: Array<{
      __typename?: 'Inventory';
      id: string;
      vintage: string;
      weight_kg: number;
      allocated_weight_kg: number;
      retired_weight_kg: number;
      price_per_kg: number;
      price_per_kg_in_gbp: number;
      currency: CurrencyEnum;
      project: {
        __typename?: 'Project';
        name: string;
        id: string;
        public_id: string;
      };
      registry: {
        __typename?: 'Registry';
        id: string;
        name: string;
        public_id: string;
      };
    }>;
  };
};

export type GetInventoryPropertyValuesQueryVariables = Exact<{
  key: InventoryFilterPropertiesEnum;
  value: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type GetInventoryPropertyValuesQuery = {
  __typename?: 'Query';
  inventory_property_values: Array<{
    __typename?: 'FilterValues';
    value: string;
    label?: string | null;
    count: number;
  }>;
};

export type RemoveInventoryMutationVariables = Exact<{
  data: RemoveInventoryInput;
}>;

export type RemoveInventoryMutation = {
  __typename?: 'Mutation';
  remove_inventory: boolean;
};

export type InventoryEvent_FromGetInventoryEventFragment = {
  __typename?: 'InventoryEvent';
  id: string;
  type: InventoryEventTypeEnum;
  source: string;
  project_id?: string | null;
  vintage?: string | null;
  registry_id?: string | null;
  portfolio_id?: string | null;
  weight_kg?: number | null;
  currency?: CurrencyEnum | null;
  price_per_kg?: number | null;
  price_per_kg_in_gbp?: number | null;
  rrp_min?: number | null;
  rrp_max?: number | null;
  allocated_weight_kg?: number | null;
  retired_weight_kg?: number | null;
  reason?: string | null;
  notes?: string | null;
  created_date: string;
  project?: { __typename?: 'Project'; name: string; public_id: string } | null;
  projects?: Array<{
    __typename?: 'ProjectsAllocatedList';
    project_id: string;
    vintage: string;
    registry: string;
    weight_kg: number;
  }> | null;
  registry?: {
    __typename?: 'Registry';
    name: string;
    public_id: string;
  } | null;
  meta_data?: Array<{
    __typename?: 'Metadata';
    key: string;
    value?: string | null;
  }> | null;
  pledge_meta_data?: Array<{
    __typename?: 'Metadata';
    key: string;
    value?: string | null;
  }> | null;
};

export type GetInventoryEventQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetInventoryEventQuery = {
  __typename?: 'Query';
  inventory_event: {
    __typename?: 'InventoryEvent';
    id: string;
    type: InventoryEventTypeEnum;
    source: string;
    project_id?: string | null;
    vintage?: string | null;
    registry_id?: string | null;
    portfolio_id?: string | null;
    weight_kg?: number | null;
    currency?: CurrencyEnum | null;
    price_per_kg?: number | null;
    price_per_kg_in_gbp?: number | null;
    rrp_min?: number | null;
    rrp_max?: number | null;
    allocated_weight_kg?: number | null;
    retired_weight_kg?: number | null;
    reason?: string | null;
    notes?: string | null;
    created_date: string;
    project?: {
      __typename?: 'Project';
      name: string;
      public_id: string;
    } | null;
    projects?: Array<{
      __typename?: 'ProjectsAllocatedList';
      project_id: string;
      vintage: string;
      registry: string;
      weight_kg: number;
    }> | null;
    registry?: {
      __typename?: 'Registry';
      name: string;
      public_id: string;
    } | null;
    meta_data?: Array<{
      __typename?: 'Metadata';
      key: string;
      value?: string | null;
    }> | null;
    pledge_meta_data?: Array<{
      __typename?: 'Metadata';
      key: string;
      value?: string | null;
    }> | null;
  };
};

export type InventoryEvent_FromGetInventoryEventsFragment = {
  __typename?: 'InventoryEvent';
  id: string;
  type: InventoryEventTypeEnum;
  source: string;
  project_id?: string | null;
  vintage?: string | null;
  registry_id?: string | null;
  portfolio_id?: string | null;
  weight_kg?: number | null;
  currency?: CurrencyEnum | null;
  price_per_kg?: number | null;
  price_per_kg_in_gbp?: number | null;
  rrp_min?: number | null;
  rrp_max?: number | null;
  allocated_weight_kg?: number | null;
  retired_weight_kg?: number | null;
  reason?: string | null;
  notes?: string | null;
  created_date: string;
  project?: { __typename?: 'Project'; name: string; public_id: string } | null;
  registry?: {
    __typename?: 'Registry';
    name: string;
    public_id: string;
  } | null;
  portfolio?: {
    __typename?: 'Portfolio';
    name: string;
    public_id: string;
  } | null;
};

export type GetInventoryEventsQueryVariables = Exact<{
  page: Scalars['Int']['input'];
  size: Scalars['Int']['input'];
  vintages?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  projectPublicIds?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  eventTypes?: InputMaybe<
    Array<InventoryEventTypeEnum> | InventoryEventTypeEnum
  >;
  sortedColumn?: InputMaybe<InventoryEventsSortedColumnEnum>;
  sortedOrder?: InputMaybe<SortedOrderEnum>;
}>;

export type GetInventoryEventsQuery = {
  __typename?: 'Query';
  inventory_events: {
    __typename?: 'GetInventoryEvents';
    total_count: number;
    items: Array<{
      __typename?: 'InventoryEvent';
      id: string;
      type: InventoryEventTypeEnum;
      source: string;
      project_id?: string | null;
      vintage?: string | null;
      registry_id?: string | null;
      portfolio_id?: string | null;
      weight_kg?: number | null;
      currency?: CurrencyEnum | null;
      price_per_kg?: number | null;
      price_per_kg_in_gbp?: number | null;
      rrp_min?: number | null;
      rrp_max?: number | null;
      allocated_weight_kg?: number | null;
      retired_weight_kg?: number | null;
      reason?: string | null;
      notes?: string | null;
      created_date: string;
      project?: {
        __typename?: 'Project';
        name: string;
        public_id: string;
      } | null;
      registry?: {
        __typename?: 'Registry';
        name: string;
        public_id: string;
      } | null;
      portfolio?: {
        __typename?: 'Portfolio';
        name: string;
        public_id: string;
      } | null;
    }>;
  };
};

export type GetInventoryEventsPropertyValuesQueryVariables = Exact<{
  key: InventoryEventsFilterPropertiesEnum;
  value: Array<Scalars['String']['input']> | Scalars['String']['input'];
  projectPublicIds?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
}>;

export type GetInventoryEventsPropertyValuesQuery = {
  __typename?: 'Query';
  inventory_events_property_values: Array<{
    __typename?: 'FilterValues';
    value: string;
    label?: string | null;
    count: number;
  }>;
};

export type GetOrderCo2eRangeQueryVariables = Exact<{ [key: string]: never }>;

export type GetOrderCo2eRangeQuery = {
  __typename?: 'Query';
  order_co2e_range: {
    __typename?: 'GetOrdersCo2ERange';
    min?: number | null;
    max?: number | null;
    histogram: Array<{
      __typename?: 'HistogramBucket';
      key: number;
      doc_count: number;
    }>;
  };
};

export type GetOrderFiltersQueryVariables = Exact<{ [key: string]: never }>;

export type GetOrderFiltersQuery = {
  __typename?: 'Query';
  order_metadata_keys: Array<string>;
  order_co2e_range: {
    __typename?: 'GetOrdersCo2ERange';
    min?: number | null;
    max?: number | null;
    histogram: Array<{
      __typename?: 'HistogramBucket';
      key: number;
      doc_count: number;
    }>;
  };
};

export type GetOrderMetadataValuesQueryVariables = Exact<{
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
}>;

export type GetOrderMetadataValuesQuery = {
  __typename?: 'Query';
  order_metadata_values: Array<{
    __typename?: 'FilterValues';
    value: string;
    display: string;
    count: number;
  }>;
};

export type GetOrderPropertyValuesQueryVariables = Exact<{
  key: OrderFilterPropertiesEnum;
  value: Scalars['String']['input'];
}>;

export type GetOrderPropertyValuesQuery = {
  __typename?: 'Query';
  order_property_values: Array<{
    __typename?: 'FilterValues';
    value: string;
    display: string;
    count: number;
  }>;
};

export type Order_FromGetOrdersFragment = {
  __typename?: 'Order';
  created_date: string;
  public_id: string;
  status: OrderStatusEnum;
  weight_kg: number;
  amount: number;
  currency: string;
  client: {
    __typename?: 'Client';
    public_id: string;
    legal_name?: string | null;
  };
  portfolio: {
    __typename?: 'Portfolio';
    public_id: string;
    name: string;
    portfolio_version?: string | null;
  };
};

export type GetOrdersQueryVariables = Exact<{
  page: Scalars['Int']['input'];
  size: Scalars['Int']['input'];
  publicIds?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  clientPublicIds?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  clientLegalNames?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  portfolioPublicIds?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  portfolioNames?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  invoiceNumbers?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  status?: InputMaybe<Array<OrderStatusEnum> | OrderStatusEnum>;
  co2e: RangeInput;
  sortedColumn?: InputMaybe<OrderSortedColumnEnum>;
  sortedOrder?: InputMaybe<SortedOrderEnum>;
}>;

export type GetOrdersQuery = {
  __typename?: 'Query';
  orders: {
    __typename?: 'GetOrders';
    total_count: number;
    total_co2e: number;
    pagination_limit: number;
    items: Array<{
      __typename?: 'Order';
      created_date: string;
      public_id: string;
      status: OrderStatusEnum;
      weight_kg: number;
      amount: number;
      currency: string;
      client: {
        __typename?: 'Client';
        public_id: string;
        legal_name?: string | null;
      };
      portfolio: {
        __typename?: 'Portfolio';
        public_id: string;
        name: string;
        portfolio_version?: string | null;
      };
    }>;
  };
};

export type Portfolio_FromGetPortfolioFragment = {
  __typename?: 'Portfolio';
  id: string;
  public_id: string;
  is_published: boolean;
  name: string;
  display_name: string;
  portfolio_version?: string | null;
  description: string;
  allocated_weight_kg: number;
  reserved_weight_kg: number;
  created_date: string;
  updated_date: string;
  prices: Array<{
    __typename?: 'PortfolioPrice';
    currency: string;
    price_per_kg: number;
  }>;
  allocations: Array<{
    __typename?: 'PortfolioAllocation';
    percentage: number;
    project: {
      __typename?: 'Project';
      public_id: string;
      name: string;
      description?: string | null;
      avoidance: boolean;
      removal: boolean;
      coordinates?: {
        __typename?: 'Geography';
        coordinates: Array<number>;
      } | null;
      project_type: {
        __typename?: 'ProjectType';
        name: string;
        category: string;
      };
      country: { __typename?: 'Country'; name: string; code: string };
      media?: Array<{
        __typename?: 'S3Object';
        bucket: string;
        key: string;
      }> | null;
    };
  }>;
  clients?: Array<{
    __typename?: 'Client';
    public_id: string;
    legal_name?: string | null;
  }> | null;
  distribution?: {
    __typename?: 'PortfolioDistribution';
    totalAllocated: number;
    avoidance: number;
    removal: number;
  } | null;
};

export type KeyFact_FromGetPortfolioFragment = {
  __typename?: 'PortfolioKeyFact';
  id: string;
  key_facts: Array<string>;
};

export type Project_FromGetPortfolioFragment = {
  __typename?: 'Project';
  public_id: string;
  registry?: {
    __typename?: 'Registry';
    id: string;
    registry_retirement_fee_per_tonne?: number | null;
  } | null;
};

export type GetPortfolioQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  public_id?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetPortfolioQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'GetPortfolio';
    portfolio: {
      __typename?: 'Portfolio';
      id: string;
      public_id: string;
      is_published: boolean;
      name: string;
      display_name: string;
      portfolio_version?: string | null;
      description: string;
      allocated_weight_kg: number;
      reserved_weight_kg: number;
      created_date: string;
      updated_date: string;
      prices: Array<{
        __typename?: 'PortfolioPrice';
        currency: string;
        price_per_kg: number;
      }>;
      allocations: Array<{
        __typename?: 'PortfolioAllocation';
        percentage: number;
        project: {
          __typename?: 'Project';
          public_id: string;
          name: string;
          description?: string | null;
          avoidance: boolean;
          removal: boolean;
          coordinates?: {
            __typename?: 'Geography';
            coordinates: Array<number>;
          } | null;
          project_type: {
            __typename?: 'ProjectType';
            name: string;
            category: string;
          };
          country: { __typename?: 'Country'; name: string; code: string };
          media?: Array<{
            __typename?: 'S3Object';
            bucket: string;
            key: string;
          }> | null;
        };
      }>;
      clients?: Array<{
        __typename?: 'Client';
        public_id: string;
        legal_name?: string | null;
      }> | null;
      distribution?: {
        __typename?: 'PortfolioDistribution';
        totalAllocated: number;
        avoidance: number;
        removal: number;
      } | null;
    };
    keyFacts?: {
      __typename?: 'PortfolioKeyFact';
      id: string;
      key_facts: Array<string>;
    } | null;
    projects: Array<{
      __typename?: 'Project';
      public_id: string;
      registry?: {
        __typename?: 'Registry';
        id: string;
        registry_retirement_fee_per_tonne?: number | null;
      } | null;
    }>;
  };
};

export type Portfolio_FromGetPortfolioStatsFragment = {
  __typename?: 'PortfolioStats';
  total_orders: number;
};

export type GetPortfolioStatsQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetPortfolioStatsQuery = {
  __typename?: 'Query';
  portfolio_stats: { __typename?: 'PortfolioStats'; total_orders: number };
};

export type PortfolioDataMapper_FromGetPortfoliosFragment = {
  __typename?: 'PortfolioDataMapper';
  id: string;
  public_id?: string | null;
  name?: string | null;
  display_name?: string | null;
  portfolio_version?: string | null;
  allocated_weight_kg?: number | null;
  reserved_weight_kg?: number | null;
  is_published?: boolean | null;
  is_draft?: boolean | null;
  status?: PortfolioStatusEnum | null;
  created_date: string;
  prices?: Array<{
    __typename?: 'PortfolioPrice';
    currency: string;
    price_per_kg: number;
  }> | null;
};

export type GetPortfoliosQueryVariables = Exact<{
  page: Scalars['Int']['input'];
  size: Scalars['Int']['input'];
  orderColumn: PortfolioOrderColumnEnum;
  orderDirection: OrderDirectionEnum;
}>;

export type GetPortfoliosQuery = {
  __typename?: 'Query';
  portfolios: {
    __typename?: 'GetPortfolios';
    total_count: number;
    items: Array<{
      __typename?: 'PortfolioDataMapper';
      id: string;
      public_id?: string | null;
      name?: string | null;
      display_name?: string | null;
      portfolio_version?: string | null;
      allocated_weight_kg?: number | null;
      reserved_weight_kg?: number | null;
      is_published?: boolean | null;
      is_draft?: boolean | null;
      status?: PortfolioStatusEnum | null;
      created_date: string;
      prices?: Array<{
        __typename?: 'PortfolioPrice';
        currency: string;
        price_per_kg: number;
      }> | null;
    }>;
  };
};

export type PublishPortfolioMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type PublishPortfolioMutation = {
  __typename?: 'Mutation';
  publish_portfolio: boolean;
};

export type UnpublishPortfolioMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type UnpublishPortfolioMutation = {
  __typename?: 'Mutation';
  unpublish_portfolio: boolean;
};

export type CreatePortfolioMutationVariables = Exact<{
  data: PortfolioDraftInput;
}>;

export type CreatePortfolioMutation = {
  __typename?: 'Mutation';
  create_portfolio: string;
};

export type PortfolioDraft_FromGetPortfolioDraftFragment = {
  __typename?: 'PortfolioDraft';
  id: string;
  portfolio_draft: {
    __typename?: 'PortfolioDraftJsonType';
    acl?: Array<string> | null;
    details?: {
      __typename?: 'PortfolioDraftDetailsType';
      name?: string | null;
      description?: string | null;
      accounting_code?: string | null;
      currency?: CurrencyEnum | null;
      key_facts?: Array<string> | null;
      portfolio_version?: string | null;
    } | null;
    allocations?: Array<{
      __typename?: 'PortfolioDetailsAllocationsType';
      project_id: string;
      project_name: string;
      registry_id: string;
      registry: string;
      weight_tonnes: number;
      vintage: string;
      price_per_kg_in_gbp: number;
      avoidance: boolean;
      removal: boolean;
      registry_fee_per_tonne: number;
    }> | null;
  };
};

export type GetPortfolioDraftQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetPortfolioDraftQuery = {
  __typename?: 'Query';
  portfolio_draft: {
    __typename?: 'PortfolioDraft';
    id: string;
    portfolio_draft: {
      __typename?: 'PortfolioDraftJsonType';
      acl?: Array<string> | null;
      details?: {
        __typename?: 'PortfolioDraftDetailsType';
        name?: string | null;
        description?: string | null;
        accounting_code?: string | null;
        currency?: CurrencyEnum | null;
        key_facts?: Array<string> | null;
        portfolio_version?: string | null;
      } | null;
      allocations?: Array<{
        __typename?: 'PortfolioDetailsAllocationsType';
        project_id: string;
        project_name: string;
        registry_id: string;
        registry: string;
        weight_tonnes: number;
        vintage: string;
        price_per_kg_in_gbp: number;
        avoidance: boolean;
        removal: boolean;
        registry_fee_per_tonne: number;
      }> | null;
    };
  };
};

export type PortfolioDraft_FromUpsertPortfolioDraftFragment = {
  __typename?: 'PortfolioDraft';
  id: string;
};

export type UpsertPortfolioDraftMutationVariables = Exact<{
  data: PortfolioDraftInput;
}>;

export type UpsertPortfolioDraftMutation = {
  __typename?: 'Mutation';
  upsert_portfolio_draft: { __typename?: 'PortfolioDraft'; id: string };
};

export type Project_FromGetProjectFragment = {
  __typename?: 'Project';
  id: string;
  public_id: string;
  name: string;
  description?: string | null;
  website?: string | null;
  location?: string | null;
  removal: boolean;
  avoidance: boolean;
  sdgs?: Array<SustainableDevelopmentGoalsEnum> | null;
  registry_project_id?: string | null;
  registry_project_url?: string | null;
  contact?: string | null;
  created_date: string;
  updated_date: string;
  country: { __typename?: 'Country'; id: string; name: string; code: string };
  coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
  project_type: {
    __typename?: 'ProjectType';
    id: string;
    name: string;
    category: string;
  };
  developer?: {
    __typename?: 'ProjectDeveloper';
    id: string;
    name: string;
    description?: string | null;
    website?: string | null;
    media?: Array<{
      __typename?: 'S3Object';
      location?: string | null;
      key: string;
      bucket: string;
    }> | null;
  } | null;
  owner?: { __typename?: 'ProjectOwner'; id: string; name: string } | null;
  registry?: {
    __typename?: 'Registry';
    id: string;
    name: string;
    website?: string | null;
    media?: Array<{
      __typename?: 'S3Object';
      location?: string | null;
      key: string;
      bucket: string;
    }> | null;
  } | null;
  certifications?: Array<{
    __typename?: 'ProjectCertification';
    id: string;
    name: string;
    media?: Array<{
      __typename?: 'S3Object';
      location?: string | null;
      key: string;
      bucket: string;
    }> | null;
  }> | null;
  carbonplan_assessment?: {
    __typename?: 'CarbonplanAssessment';
    data_stringify?: string | null;
  } | null;
  media?: Array<{
    __typename?: 'S3Object';
    location?: string | null;
    bucket: string;
    eTag?: string | null;
    key: string;
  }> | null;
};

export type GetProjectQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  public_id?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetProjectQuery = {
  __typename?: 'Query';
  project: {
    __typename?: 'Project';
    id: string;
    public_id: string;
    name: string;
    description?: string | null;
    website?: string | null;
    location?: string | null;
    removal: boolean;
    avoidance: boolean;
    sdgs?: Array<SustainableDevelopmentGoalsEnum> | null;
    registry_project_id?: string | null;
    registry_project_url?: string | null;
    contact?: string | null;
    created_date: string;
    updated_date: string;
    country: { __typename?: 'Country'; id: string; name: string; code: string };
    coordinates?: {
      __typename?: 'Geography';
      coordinates: Array<number>;
    } | null;
    project_type: {
      __typename?: 'ProjectType';
      id: string;
      name: string;
      category: string;
    };
    developer?: {
      __typename?: 'ProjectDeveloper';
      id: string;
      name: string;
      description?: string | null;
      website?: string | null;
      media?: Array<{
        __typename?: 'S3Object';
        location?: string | null;
        key: string;
        bucket: string;
      }> | null;
    } | null;
    owner?: { __typename?: 'ProjectOwner'; id: string; name: string } | null;
    registry?: {
      __typename?: 'Registry';
      id: string;
      name: string;
      website?: string | null;
      media?: Array<{
        __typename?: 'S3Object';
        location?: string | null;
        key: string;
        bucket: string;
      }> | null;
    } | null;
    certifications?: Array<{
      __typename?: 'ProjectCertification';
      id: string;
      name: string;
      media?: Array<{
        __typename?: 'S3Object';
        location?: string | null;
        key: string;
        bucket: string;
      }> | null;
    }> | null;
    carbonplan_assessment?: {
      __typename?: 'CarbonplanAssessment';
      data_stringify?: string | null;
    } | null;
    media?: Array<{
      __typename?: 'S3Object';
      location?: string | null;
      bucket: string;
      eTag?: string | null;
      key: string;
    }> | null;
  };
};

export type GetProjectPropertyValuesQueryVariables = Exact<{
  key: ProjectFilterPropertiesEnum;
  value: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type GetProjectPropertyValuesQuery = {
  __typename?: 'Query';
  project_property_values: Array<{
    __typename?: 'FilterValues';
    value: string;
    label?: string | null;
    count: number;
  }>;
};

export type Project_FromGetProjectsFragment = {
  __typename?: 'Project';
  id: string;
  name: string;
  public_id: string;
  project_type: { __typename?: 'ProjectType'; name: string; category: string };
  country: { __typename?: 'Country'; name: string };
  media?: Array<{
    __typename?: 'S3Object';
    bucket: string;
    key: string;
  }> | null;
  registry?: { __typename?: 'Registry'; id: string; name: string } | null;
};

export type GetProjectsQueryVariables = Exact<{
  projectName?: InputMaybe<Scalars['String']['input']>;
  projectPublicIds?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  page?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  sortedColumn?: InputMaybe<ProjectSortedColumnEnum>;
  sortedOrder?: InputMaybe<SortedOrderEnum>;
}>;

export type GetProjectsQuery = {
  __typename?: 'Query';
  projects: {
    __typename?: 'GetProjects';
    total_count: number;
    items: Array<{
      __typename?: 'Project';
      id: string;
      name: string;
      public_id: string;
      project_type: {
        __typename?: 'ProjectType';
        name: string;
        category: string;
      };
      country: { __typename?: 'Country'; name: string };
      media?: Array<{
        __typename?: 'S3Object';
        bucket: string;
        key: string;
      }> | null;
      registry?: { __typename?: 'Registry'; id: string; name: string } | null;
    }>;
  };
};

export type Project_FromUpsertProjectFragment = {
  __typename?: 'Project';
  id: string;
  public_id: string;
};

export type UpsertProjectMutationVariables = Exact<{
  data: UpsertProjectInput;
}>;

export type UpsertProjectMutation = {
  __typename?: 'Mutation';
  upsert_project: { __typename?: 'Project'; id: string; public_id: string };
};

export type ProjectCertification_FromProjectCertificationFragment = {
  __typename?: 'ProjectCertification';
  id: string;
  name: string;
  description?: string | null;
  registry_link: string;
  updated_date: string;
  media?: Array<{
    __typename?: 'S3Object';
    location?: string | null;
    bucket: string;
    eTag?: string | null;
    key: string;
  }> | null;
};

export type GetProjectCertificationQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetProjectCertificationQuery = {
  __typename?: 'Query';
  project_certification: {
    __typename?: 'ProjectCertification';
    id: string;
    name: string;
    description?: string | null;
    registry_link: string;
    updated_date: string;
    media?: Array<{
      __typename?: 'S3Object';
      location?: string | null;
      bucket: string;
      eTag?: string | null;
      key: string;
    }> | null;
  };
};

export type ProjectCertification_FromGetProjectCertificationsFragment = {
  __typename?: 'ProjectCertification';
  id: string;
  name: string;
  media?: Array<{
    __typename?: 'S3Object';
    location?: string | null;
    bucket: string;
    eTag?: string | null;
    key: string;
  }> | null;
};

export type GetProjectCertificationsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetProjectCertificationsQuery = {
  __typename?: 'Query';
  project_certifications: Array<{
    __typename?: 'ProjectCertification';
    id: string;
    name: string;
    media?: Array<{
      __typename?: 'S3Object';
      location?: string | null;
      bucket: string;
      eTag?: string | null;
      key: string;
    }> | null;
  }>;
};

export type ProjectCertification_FromUpsertProjectCertificationFragment = {
  __typename?: 'ProjectCertification';
  id: string;
};

export type UpsertProjectCertificationMutationVariables = Exact<{
  data: UpsertProjectCertificationInput;
}>;

export type UpsertProjectCertificationMutation = {
  __typename?: 'Mutation';
  upsert_project_certification: {
    __typename?: 'ProjectCertification';
    id: string;
  };
};

export type ProjectDeveloper_FromGetProjectDeveloperFragment = {
  __typename?: 'ProjectDeveloper';
  id: string;
  name: string;
  public_id: string;
  description?: string | null;
  website?: string | null;
  projects: Array<{
    __typename?: 'Project';
    name: string;
    public_id: string;
    project_type: {
      __typename?: 'ProjectType';
      name: string;
      category: string;
    };
    country: { __typename?: 'Country'; name: string };
  }>;
  media?: Array<{
    __typename?: 'S3Object';
    location?: string | null;
    bucket: string;
    eTag?: string | null;
    key: string;
  }> | null;
};

export type GetProjectDeveloperQueryVariables = Exact<{
  public_id: Scalars['String']['input'];
}>;

export type GetProjectDeveloperQuery = {
  __typename?: 'Query';
  project_developer: {
    __typename?: 'ProjectDeveloper';
    id: string;
    name: string;
    public_id: string;
    description?: string | null;
    website?: string | null;
    projects: Array<{
      __typename?: 'Project';
      name: string;
      public_id: string;
      project_type: {
        __typename?: 'ProjectType';
        name: string;
        category: string;
      };
      country: { __typename?: 'Country'; name: string };
    }>;
    media?: Array<{
      __typename?: 'S3Object';
      location?: string | null;
      bucket: string;
      eTag?: string | null;
      key: string;
    }> | null;
  };
};

export type ProjectDeveloper_FromGetProjectDevelopersFragment = {
  __typename?: 'ProjectDeveloper';
  name: string;
  public_id: string;
  projects: Array<{ __typename?: 'Project'; name: string; public_id: string }>;
  media?: Array<{
    __typename?: 'S3Object';
    location?: string | null;
    bucket: string;
    key: string;
  }> | null;
};

export type GetProjectDevelopersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetProjectDevelopersQuery = {
  __typename?: 'Query';
  project_developers: Array<{
    __typename?: 'ProjectDeveloper';
    name: string;
    public_id: string;
    projects: Array<{
      __typename?: 'Project';
      name: string;
      public_id: string;
    }>;
    media?: Array<{
      __typename?: 'S3Object';
      location?: string | null;
      bucket: string;
      key: string;
    }> | null;
  }>;
};

export type ProjectDeveloperNameId_FromGetProjectDevelopersFragment = {
  __typename?: 'ProjectDeveloper';
  name: string;
  id: string;
};

export type GetProjectDevelopersNameIdQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetProjectDevelopersNameIdQuery = {
  __typename?: 'Query';
  project_developers: Array<{
    __typename?: 'ProjectDeveloper';
    name: string;
    id: string;
  }>;
};

export type ProjectDeveloper_FromUpsertProjectDeveloperFragment = {
  __typename?: 'ProjectDeveloper';
  id: string;
};

export type UpsertProjectDeveloperMutationVariables = Exact<{
  data: UpsertDeveloperInput;
}>;

export type UpsertProjectDeveloperMutation = {
  __typename?: 'Mutation';
  upsert_project_developer: { __typename?: 'ProjectDeveloper'; id: string };
};

export type ProjectOwner_FromGetProjectOwnerFragment = {
  __typename?: 'ProjectOwner';
  id: string;
  name: string;
  description?: string | null;
  updated_date: string;
};

export type GetProjectOwnerQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetProjectOwnerQuery = {
  __typename?: 'Query';
  project_owner: {
    __typename?: 'ProjectOwner';
    id: string;
    name: string;
    description?: string | null;
    updated_date: string;
  };
};

export type ProjectOwner_FromGetProjectOwnersFragment = {
  __typename?: 'ProjectOwner';
  id: string;
  name: string;
  description?: string | null;
};

export type GetProjectOwnersQueryVariables = Exact<{ [key: string]: never }>;

export type GetProjectOwnersQuery = {
  __typename?: 'Query';
  project_owners: Array<{
    __typename?: 'ProjectOwner';
    id: string;
    name: string;
    description?: string | null;
  }>;
};

export type ProjectOwner_FromUpsertProjectOwnerFragment = {
  __typename?: 'ProjectOwner';
  id: string;
};

export type UpsertProjectOwnerMutationVariables = Exact<{
  data: ProjectOwnerInput;
}>;

export type UpsertProjectOwnerMutation = {
  __typename?: 'Mutation';
  upsert_project_owner: { __typename?: 'ProjectOwner'; id: string };
};

export type ProjectType_FromGetProjectTypeFragment = {
  __typename?: 'ProjectType';
  id: string;
  name: string;
  category: string;
};

export type GetProjectTypeQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetProjectTypeQuery = {
  __typename?: 'Query';
  project_type: {
    __typename?: 'ProjectType';
    id: string;
    name: string;
    category: string;
  };
};

export type GetProjectTypeCategoriesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetProjectTypeCategoriesQuery = {
  __typename?: 'Query';
  project_type_categories: Array<{
    __typename?: 'ProjectType';
    category: string;
  }>;
};

export type ProjectType_FromGetProjectTypesFragment = {
  __typename?: 'ProjectType';
  name: string;
  category: string;
  id: string;
};

export type GetProjectTypesQueryVariables = Exact<{ [key: string]: never }>;

export type GetProjectTypesQuery = {
  __typename?: 'Query';
  project_types: Array<{
    __typename?: 'ProjectType';
    name: string;
    category: string;
    id: string;
  }>;
};

export type ProjectType_FromUpsertProjectTypeFragment = {
  __typename?: 'ProjectType';
  id: string;
};

export type UpsertProjectTypeMutationVariables = Exact<{
  data: ProjectTypeInput;
}>;

export type UpsertProjectTypeMutation = {
  __typename?: 'Mutation';
  upsert_project_type: { __typename?: 'ProjectType'; id: string };
};

export type Registry_FromGetRegistriesFragment = {
  __typename?: 'Registry';
  id: string;
  public_id: string;
  name: string;
  website?: string | null;
  registry_retirement_fee_per_tonne?: number | null;
  media?: Array<{
    __typename?: 'S3Object';
    location?: string | null;
    bucket: string;
    eTag?: string | null;
    key: string;
  }> | null;
};

export type GetRegistriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetRegistriesQuery = {
  __typename?: 'Query';
  registries: Array<{
    __typename?: 'Registry';
    id: string;
    public_id: string;
    name: string;
    website?: string | null;
    registry_retirement_fee_per_tonne?: number | null;
    media?: Array<{
      __typename?: 'S3Object';
      location?: string | null;
      bucket: string;
      eTag?: string | null;
      key: string;
    }> | null;
  }>;
};

export type Registry_FromGetRegistryFragment = {
  __typename?: 'Registry';
  id: string;
  public_id: string;
  name: string;
  website?: string | null;
  infrastructure?: string | null;
  projects_base_url?: string | null;
  has_automated_retirement?: boolean | null;
  registry_retirement_fee_per_tonne?: number | null;
  updated_date: string;
  media?: Array<{
    __typename?: 'S3Object';
    location?: string | null;
    bucket: string;
    eTag?: string | null;
    key: string;
  }> | null;
};

export type GetRegistryQueryVariables = Exact<{
  public_id: Scalars['String']['input'];
}>;

export type GetRegistryQuery = {
  __typename?: 'Query';
  registry: {
    __typename?: 'Registry';
    id: string;
    public_id: string;
    name: string;
    website?: string | null;
    infrastructure?: string | null;
    projects_base_url?: string | null;
    has_automated_retirement?: boolean | null;
    registry_retirement_fee_per_tonne?: number | null;
    updated_date: string;
    media?: Array<{
      __typename?: 'S3Object';
      location?: string | null;
      bucket: string;
      eTag?: string | null;
      key: string;
    }> | null;
  };
};

export type Registry_FromUpsertRegistryFragment = {
  __typename?: 'Registry';
  id: string;
  public_id: string;
};

export type UpsertRegistryMutationVariables = Exact<{
  data: RegistryInput;
}>;

export type UpsertRegistryMutation = {
  __typename?: 'Mutation';
  upsert_registry: { __typename?: 'Registry'; id: string; public_id: string };
};

export type ConfirmRetirementMutationVariables = Exact<{
  data: ConfirmRetirementInput;
}>;

export type ConfirmRetirementMutation = {
  __typename?: 'Mutation';
  confirm_retirement: boolean;
};

export type RetirementConfirmed_FromGetRetirementsConfirmedFragment = {
  __typename?: 'RetirementConfirmed';
  vintage: string;
  retirement_id: string;
  retirement_url: string;
  weight_kg: number;
  created_date: string;
  serial_numbers: Array<string>;
  project: { __typename?: 'Project'; name: string; public_id: string };
  registry?: {
    __typename?: 'Registry';
    name: string;
    public_id: string;
  } | null;
};

export type GetRetirementsConfirmedQueryVariables = Exact<{
  page: Scalars['Int']['input'];
  size: Scalars['Int']['input'];
  sortedColumn?: InputMaybe<RetirementSortedColumnEnum>;
  sortedOrder?: InputMaybe<SortedOrderEnum>;
}>;

export type GetRetirementsConfirmedQuery = {
  __typename?: 'Query';
  retirements_confirmed: {
    __typename?: 'GetRetirementsConfirmed';
    total_count: number;
    items: Array<{
      __typename?: 'RetirementConfirmed';
      vintage: string;
      retirement_id: string;
      retirement_url: string;
      weight_kg: number;
      created_date: string;
      serial_numbers: Array<string>;
      project: { __typename?: 'Project'; name: string; public_id: string };
      registry?: {
        __typename?: 'Registry';
        name: string;
        public_id: string;
      } | null;
    }>;
  };
};

export type RetirementRequested_FromGetRetirementsRequestedFragment = {
  __typename?: 'RetirementRequested';
  retirement_id: string;
  vintage: string;
  weight_kg: number;
  created_date: string;
  project: { __typename?: 'Project'; name: string; public_id: string };
  registry?: {
    __typename?: 'Registry';
    name: string;
    public_id: string;
  } | null;
};

export type GetRetirementsRequestedQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetRetirementsRequestedQuery = {
  __typename?: 'Query';
  retirements_requested: Array<{
    __typename?: 'RetirementRequested';
    retirement_id: string;
    vintage: string;
    weight_kg: number;
    created_date: string;
    project: { __typename?: 'Project'; name: string; public_id: string };
    registry?: {
      __typename?: 'Registry';
      name: string;
      public_id: string;
    } | null;
  }>;
};

export type MigrateClientsToChargebeeMutationVariables = Exact<{
  [key: string]: never;
}>;

export type MigrateClientsToChargebeeMutation = {
  __typename?: 'Mutation';
  migrate_clients_to_chargebee: boolean;
};

export type ReconcileInventoryMutationVariables = Exact<{
  [key: string]: never;
}>;

export type ReconcileInventoryMutation = {
  __typename?: 'Mutation';
  reconcile_inventory: boolean;
};

export type SyncPortfoliosInChargebeeMutationVariables = Exact<{
  [key: string]: never;
}>;

export type SyncPortfoliosInChargebeeMutation = {
  __typename?: 'Mutation';
  sync_portfolios_in_chargebee: boolean;
};

export type TriggerNetlifyDeployMutationVariables = Exact<{
  [key: string]: never;
}>;

export type TriggerNetlifyDeployMutation = {
  __typename?: 'Mutation';
  trigger_netlify_deploy: boolean;
};

export type User_FromMeFragment = {
  __typename?: 'User';
  sub: string;
  username: string;
  email_verified: string;
  name: string;
  email: string;
};

export type GetMeQueryVariables = Exact<{ [key: string]: never }>;

export type GetMeQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    sub: string;
    username: string;
    email_verified: string;
    name: string;
    email: string;
  };
};

export const InventoryReconciliationResults_FromGetInventoryReconciliationResultsFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value:
            'InventoryReconciliationResults_fromGetInventoryReconciliationResults',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'InventoryReconciliationResults' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
            { kind: 'Field', name: { kind: 'Name', value: 'error_count' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'recon_results_summary_file_url' },
            },
            {
              kind: 'Field',
              name: {
                kind: 'Name',
                value: 'recon_external_inventory_file_url',
              },
            },
            {
              kind: 'Field',
              name: {
                kind: 'Name',
                value: 'recon_internal_inventory_file_url',
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    InventoryReconciliationResults_FromGetInventoryReconciliationResultsFragment,
    unknown
  >;
export const ApiKey_FromGetApiKeysFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ApiKey_fromGetApiKeys' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ApiKey' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'api_key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApiKey_FromGetApiKeysFragment, unknown>;
export const ApiLog_FromGetApiLogsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ApiLog_fromGetApiLogs' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ApiLog' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'api_key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endpoint' } },
          { kind: 'Field', name: { kind: 'Name', value: 'response_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'request' } },
          { kind: 'Field', name: { kind: 'Name', value: 'response' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApiLog_FromGetApiLogsFragment, unknown>;
export const Client_FromCreateClientFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Client_fromCreateClient' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Client' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
      },
    },
  ],
} as unknown as DocumentNode<Client_FromCreateClientFragment, unknown>;
export const ClientUser_FromGetClientFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientUser_fromGetClient' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ClientUser' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'role' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'account_state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClientUser_FromGetClientFragment, unknown>;
export const ClientSubscriptionEntitlement_FromGetClientFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ClientSubscriptionEntitlement_fromGetClient',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ClientSubscriptionEntitlement' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'feature_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'feature_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_enabled' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ClientSubscriptionEntitlement_FromGetClientFragment,
  unknown
>;
export const Client_FromGetClientFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Client_fromGetClient' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Client' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address_1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address_2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'region' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billing_email_address' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'client_function' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tax_id_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tax_id_value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUser_fromGetClient' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'domains' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entitlements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientSubscriptionEntitlement_fromGetClient',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientUser_fromGetClient' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ClientUser' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'role' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'account_state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ClientSubscriptionEntitlement_fromGetClient',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ClientSubscriptionEntitlement' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'feature_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'feature_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_enabled' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Client_FromGetClientFragment, unknown>;
export const ClientUser_FromGetClientIntegrationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientUser_fromGetClientIntegration' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ClientUser' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ClientUser_FromGetClientIntegrationFragment,
  unknown
>;
export const Client_FromGetClientIntegrationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Client_fromGetClientIntegration' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Client' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientUser_fromGetClientIntegration',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientUser_fromGetClientIntegration' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ClientUser' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Client_FromGetClientIntegrationFragment, unknown>;
export const CargoWiseEAdaptorOutboundConfig_FromGetClientIntegrationsFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'CargoWiseEAdaptorOutboundConfig_fromGetClientIntegrations',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'CargoWiseEAdaptorOutboundConfig' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'sender_id' } },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    CargoWiseEAdaptorOutboundConfig_FromGetClientIntegrationsFragment,
    unknown
  >;
export const ClientIntegration_FromGetClientIntegrationsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ClientIntegration_fromGetClientIntegrations',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ClientIntegration' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'system' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondary_system' } },
          { kind: 'Field', name: { kind: 'Name', value: 'test' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'status_update_date' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'status_update_requested_by' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'configuration_stringified' },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'cargowise_eadaptor_outbound_configs',
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'CargoWiseEAdaptorOutboundConfig_fromGetClientIntegrations',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'CargoWiseEAdaptorOutboundConfig_fromGetClientIntegrations',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CargoWiseEAdaptorOutboundConfig' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender_id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ClientIntegration_FromGetClientIntegrationsFragment,
  unknown
>;
export const Client_FromGetClientsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Client_fromGetClients' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Client' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'address_1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address_2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'region' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account_state' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Client_FromGetClientsFragment, unknown>;
export const Client_FromGetClientsIdNameFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Client_fromGetClientsIdName' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Client' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Client_FromGetClientsIdNameFragment, unknown>;
export const Client_FromUpdateClientFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Client_fromUpdateClient' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Client' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
      },
    },
  ],
} as unknown as DocumentNode<Client_FromUpdateClientFragment, unknown>;
export const ClientIntegration_FromUpsertClientIntegrationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ClientIntegration_fromUpsertClientIntegration',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ClientIntegration' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
      },
    },
  ],
} as unknown as DocumentNode<
  ClientIntegration_FromUpsertClientIntegrationFragment,
  unknown
>;
export const Country_FromGetCountriesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Country_fromGetCountries' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Country' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Country_FromGetCountriesFragment, unknown>;
export const Inventory_FromGetAvailableInventoryFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Inventory_fromGetAvailableInventory' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Inventory' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avoidance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'removal' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'media' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bucket' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'registry' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'registry_retirement_fee_per_tonne',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocated_weight_kg' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price_per_kg_in_gbp' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'vintage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registry' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Inventory_FromGetAvailableInventoryFragment,
  unknown
>;
export const Inventory_FromGetInventoryFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Inventory_fromGetInventory' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Inventory' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vintage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registry' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocated_weight_kg' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'retired_weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price_per_kg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price_per_kg_in_gbp' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Inventory_FromGetInventoryFragment, unknown>;
export const InventoryEvent_FromGetInventoryEventFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InventoryEvent_fromGetInventoryEvent' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InventoryEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'project_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projects' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'project_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vintage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'registry' } },
                { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'vintage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'registry_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registry' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'portfolio_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price_per_kg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price_per_kg_in_gbp' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rrp_min' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rrp_max' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocated_weight_kg' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'retired_weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meta_data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pledge_meta_data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InventoryEvent_FromGetInventoryEventFragment,
  unknown
>;
export const InventoryEvent_FromGetInventoryEventsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InventoryEvent_fromGetInventoryEvents' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InventoryEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'project_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'vintage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'registry_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registry' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'portfolio_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price_per_kg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price_per_kg_in_gbp' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rrp_min' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rrp_max' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocated_weight_kg' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'retired_weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InventoryEvent_FromGetInventoryEventsFragment,
  unknown
>;
export const Order_FromGetOrdersFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Order_fromGetOrders' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Order' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'portfolio_version' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Order_FromGetOrdersFragment, unknown>;
export const Portfolio_FromGetPortfolioFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Portfolio_fromGetPortfolio' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Portfolio' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_published' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'display_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'portfolio_version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'prices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'price_per_kg' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocated_weight_kg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reserved_weight_kg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'public_id' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avoidance' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'removal' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'coordinates' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'coordinates' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_type' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'category' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'code' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'media' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'bucket' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'key' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clients' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'distribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalAllocated' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'avoidance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'removal' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Portfolio_FromGetPortfolioFragment, unknown>;
export const KeyFact_FromGetPortfolioFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'KeyFact_fromGetPortfolio' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PortfolioKeyFact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key_facts' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<KeyFact_FromGetPortfolioFragment, unknown>;
export const Project_FromGetPortfolioFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Project_fromGetPortfolio' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Project' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registry' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'registry_retirement_fee_per_tonne',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Project_FromGetPortfolioFragment, unknown>;
export const Portfolio_FromGetPortfolioStatsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Portfolio_fromGetPortfolioStats' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PortfolioStats' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'total_orders' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Portfolio_FromGetPortfolioStatsFragment, unknown>;
export const PortfolioDataMapper_FromGetPortfoliosFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioDataMapper_fromGetPortfolios' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PortfolioDataMapper' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'display_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'portfolio_version' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'prices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'price_per_kg' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocated_weight_kg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reserved_weight_kg' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'is_published' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_draft' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PortfolioDataMapper_FromGetPortfoliosFragment,
  unknown
>;
export const PortfolioDraft_FromGetPortfolioDraftFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioDraft_fromGetPortfolioDraft' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PortfolioDraft' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio_draft' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'details' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accounting_code' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'key_facts' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'portfolio_version' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allocations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'registry_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'registry' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'weight_tonnes' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vintage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'price_per_kg_in_gbp' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avoidance' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'removal' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'registry_fee_per_tonne' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'acl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PortfolioDraft_FromGetPortfolioDraftFragment,
  unknown
>;
export const PortfolioDraft_FromUpsertPortfolioDraftFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioDraft_fromUpsertPortfolioDraft' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PortfolioDraft' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
      },
    },
  ],
} as unknown as DocumentNode<
  PortfolioDraft_FromUpsertPortfolioDraftFragment,
  unknown
>;
export const Project_FromGetProjectFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Project_fromGetProject' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Project' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avoidance' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'coordinates' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project_type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'developer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'media' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'location' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bucket' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'owner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registry' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'media' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'location' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bucket' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'certifications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'media' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'location' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bucket' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carbonplan_assessment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data_stringify' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sdgs' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eTag' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registry_project_id' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registry_project_url' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'contact' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Project_FromGetProjectFragment, unknown>;
export const Project_FromGetProjectsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Project_fromGetProjects' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Project' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project_type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registry' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Project_FromGetProjectsFragment, unknown>;
export const Project_FromUpsertProjectFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Project_fromUpsertProject' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Project' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Project_FromUpsertProjectFragment, unknown>;
export const ProjectCertification_FromProjectCertificationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ProjectCertification_fromProjectCertification',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectCertification' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eTag' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'registry_link' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProjectCertification_FromProjectCertificationFragment,
  unknown
>;
export const ProjectCertification_FromGetProjectCertificationsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ProjectCertification_fromGetProjectCertifications',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectCertification' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eTag' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProjectCertification_FromGetProjectCertificationsFragment,
  unknown
>;
export const ProjectCertification_FromUpsertProjectCertificationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ProjectCertification_fromUpsertProjectCertification',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectCertification' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
      },
    },
  ],
} as unknown as DocumentNode<
  ProjectCertification_FromUpsertProjectCertificationFragment,
  unknown
>;
export const ProjectDeveloper_FromGetProjectDeveloperFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDeveloper_fromGetProjectDeveloper' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectDeveloper' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projects' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project_type' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'category' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'country' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eTag' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProjectDeveloper_FromGetProjectDeveloperFragment,
  unknown
>;
export const ProjectDeveloper_FromGetProjectDevelopersFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ProjectDeveloper_fromGetProjectDevelopers',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectDeveloper' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projects' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProjectDeveloper_FromGetProjectDevelopersFragment,
  unknown
>;
export const ProjectDeveloperNameId_FromGetProjectDevelopersFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ProjectDeveloperNameId_fromGetProjectDevelopers',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectDeveloper' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProjectDeveloperNameId_FromGetProjectDevelopersFragment,
  unknown
>;
export const ProjectDeveloper_FromUpsertProjectDeveloperFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ProjectDeveloper_fromUpsertProjectDeveloper',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectDeveloper' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
      },
    },
  ],
} as unknown as DocumentNode<
  ProjectDeveloper_FromUpsertProjectDeveloperFragment,
  unknown
>;
export const ProjectOwner_FromGetProjectOwnerFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectOwner_fromGetProjectOwner' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectOwner' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectOwner_FromGetProjectOwnerFragment, unknown>;
export const ProjectOwner_FromGetProjectOwnersFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectOwner_fromGetProjectOwners' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectOwner' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProjectOwner_FromGetProjectOwnersFragment,
  unknown
>;
export const ProjectOwner_FromUpsertProjectOwnerFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectOwner_fromUpsertProjectOwner' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectOwner' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
      },
    },
  ],
} as unknown as DocumentNode<
  ProjectOwner_FromUpsertProjectOwnerFragment,
  unknown
>;
export const ProjectType_FromGetProjectTypeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectType_fromGetProjectType' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectType_FromGetProjectTypeFragment, unknown>;
export const ProjectType_FromGetProjectTypesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectType_fromGetProjectTypes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectType_FromGetProjectTypesFragment, unknown>;
export const ProjectType_FromUpsertProjectTypeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectType_fromUpsertProjectType' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
      },
    },
  ],
} as unknown as DocumentNode<
  ProjectType_FromUpsertProjectTypeFragment,
  unknown
>;
export const Registry_FromGetRegistriesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Registry_fromGetRegistries' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Registry' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registry_retirement_fee_per_tonne' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eTag' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Registry_FromGetRegistriesFragment, unknown>;
export const Registry_FromGetRegistryFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Registry_fromGetRegistry' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Registry' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'infrastructure' } },
          { kind: 'Field', name: { kind: 'Name', value: 'projects_base_url' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'has_automated_retirement' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registry_retirement_fee_per_tonne' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eTag' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Registry_FromGetRegistryFragment, unknown>;
export const Registry_FromUpsertRegistryFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Registry_fromUpsertRegistry' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Registry' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Registry_FromUpsertRegistryFragment, unknown>;
export const RetirementConfirmed_FromGetRetirementsConfirmedFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RetirementConfirmed_fromGetRetirementsConfirmed',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RetirementConfirmed' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'vintage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registry' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'retirement_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'retirement_url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'serial_numbers' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RetirementConfirmed_FromGetRetirementsConfirmedFragment,
  unknown
>;
export const RetirementRequested_FromGetRetirementsRequestedFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RetirementRequested_fromGetRetirementsRequested',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RetirementRequested' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registry' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'retirement_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vintage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RetirementRequested_FromGetRetirementsRequestedFragment,
  unknown
>;
export const User_FromMeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User_fromMe' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'sub' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email_verified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<User_FromMeFragment, unknown>;
export const GetInventoryReconciliationResultsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getInventoryReconciliationResults' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'inventoryReconciliationResults' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'InventoryReconciliationResults_fromGetInventoryReconciliationResults',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value:
          'InventoryReconciliationResults_fromGetInventoryReconciliationResults',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InventoryReconciliationResults' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'error_count' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recon_results_summary_file_url' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recon_external_inventory_file_url' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recon_internal_inventory_file_url' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetInventoryReconciliationResultsQuery,
  GetInventoryReconciliationResultsQueryVariables
>;
export const GetApiKeysDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getApiKeys' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'client_id' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'api_keys' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'client_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'client_id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ApiKey_fromGetApiKeys' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ApiKey_fromGetApiKeys' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ApiKey' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'api_key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetApiKeysQuery, GetApiKeysQueryVariables>;
export const GetApiLogsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAPILogs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'descend' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'exclusive_start_key' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'client_id' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'api_logs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'descend' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'descend' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'exclusive_start_key' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'exclusive_start_key' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'client_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'client_id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ApiLog_fromGetApiLogs' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'last_evaluated_key' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ApiLog_fromGetApiLogs' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ApiLog' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'api_key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endpoint' } },
          { kind: 'Field', name: { kind: 'Name', value: 'response_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'request' } },
          { kind: 'Field', name: { kind: 'Name', value: 'response' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetApiLogsQuery, GetApiLogsQueryVariables>;
export const RollApiKeyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RollApiKey' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'client_id' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roll_api_key' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'client_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'client_id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RollApiKeyMutation, RollApiKeyMutationVariables>;
export const CreateCargoWiseEAdaptorOutboundConfigDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCargoWiseEAdaptorOutboundConfig' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'client_integration_id' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sender_id' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'password' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'create_cargowise_eadaptor_outbound_config',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'client_integration_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'client_integration_id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sender_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sender_id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'password' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'password' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'CargoWiseEAdaptorOutboundConfig_fromGetClientIntegrations',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'CargoWiseEAdaptorOutboundConfig_fromGetClientIntegrations',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CargoWiseEAdaptorOutboundConfig' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender_id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCargoWiseEAdaptorOutboundConfigMutation,
  CreateCargoWiseEAdaptorOutboundConfigMutationVariables
>;
export const DeleteCargoWiseEAdaptorOutboundConfigDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteCargoWiseEAdaptorOutboundConfig' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'delete_cargowise_eadaptor_outbound_config',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteCargoWiseEAdaptorOutboundConfigMutation,
  DeleteCargoWiseEAdaptorOutboundConfigMutationVariables
>;
export const UpdateCargoWiseEAdaptorOutboundConfigDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCargoWiseEAdaptorOutboundConfig' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sender_id' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'update_cargowise_eadaptor_outbound_config',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sender_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sender_id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'CargoWiseEAdaptorOutboundConfig_fromGetClientIntegrations',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'CargoWiseEAdaptorOutboundConfig_fromGetClientIntegrations',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CargoWiseEAdaptorOutboundConfig' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender_id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCargoWiseEAdaptorOutboundConfigMutation,
  UpdateCargoWiseEAdaptorOutboundConfigMutationVariables
>;
export const UpdateCargoWiseEAdaptorOutboundConfigPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'UpdateCargoWiseEAdaptorOutboundConfigPassword',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'password' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'set_cargowise_eadaptor_outbound_config_password',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'password' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'password' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'CargoWiseEAdaptorOutboundConfig_fromGetClientIntegrations',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'CargoWiseEAdaptorOutboundConfig_fromGetClientIntegrations',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CargoWiseEAdaptorOutboundConfig' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender_id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCargoWiseEAdaptorOutboundConfigPasswordMutation,
  UpdateCargoWiseEAdaptorOutboundConfigPasswordMutationVariables
>;
export const CreateClientDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateClient' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateClientInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'create_client' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Client_fromCreateClient' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Client_fromCreateClient' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Client' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateClientMutation,
  CreateClientMutationVariables
>;
export const DeleteClientUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteClientUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_client_user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteClientUserMutation,
  DeleteClientUserMutationVariables
>;
export const GetClientDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getClient' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'publicId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'publicId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Client_fromGetClient' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientUser_fromGetClient' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ClientUser' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'role' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'account_state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ClientSubscriptionEntitlement_fromGetClient',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ClientSubscriptionEntitlement' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'feature_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'feature_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_enabled' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Client_fromGetClient' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Client' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address_1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address_2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'region' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billing_email_address' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'client_function' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tax_id_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tax_id_value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUser_fromGetClient' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'domains' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entitlements' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientSubscriptionEntitlement_fromGetClient',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetClientQuery, GetClientQueryVariables>;
export const GetClientIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getClientIntegration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'public_id' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_integration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'public_id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientIntegration_fromGetClientIntegrations',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'CargoWiseEAdaptorOutboundConfig_fromGetClientIntegrations',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CargoWiseEAdaptorOutboundConfig' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender_id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ClientIntegration_fromGetClientIntegrations',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ClientIntegration' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'system' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondary_system' } },
          { kind: 'Field', name: { kind: 'Name', value: 'test' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'status_update_date' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'status_update_requested_by' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'configuration_stringified' },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'cargowise_eadaptor_outbound_configs',
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'CargoWiseEAdaptorOutboundConfig_fromGetClientIntegrations',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetClientIntegrationQuery,
  GetClientIntegrationQueryVariables
>;
export const GetClientIntegrationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getClientIntegrations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'client_id' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_integrations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'client_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'client_id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientIntegration_fromGetClientIntegrations',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'CargoWiseEAdaptorOutboundConfig_fromGetClientIntegrations',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CargoWiseEAdaptorOutboundConfig' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender_id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ClientIntegration_fromGetClientIntegrations',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ClientIntegration' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'system' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondary_system' } },
          { kind: 'Field', name: { kind: 'Name', value: 'test' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'status_update_date' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'status_update_requested_by' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'configuration_stringified' },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'cargowise_eadaptor_outbound_configs',
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'CargoWiseEAdaptorOutboundConfig_fromGetClientIntegrations',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetClientIntegrationsQuery,
  GetClientIntegrationsQueryVariables
>;
export const GetClientsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getClients' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clients' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'size' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'size' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Client_fromGetClients' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Client_fromGetClients' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Client' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'address_1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address_2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'region' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account_state' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetClientsQuery, GetClientsQueryVariables>;
export const GetClientsIdNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getClientsIdName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clients' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'size' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'size' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'Client_fromGetClientsIdName',
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Client_fromGetClientsIdName' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Client' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetClientsIdNameQuery,
  GetClientsIdNameQueryVariables
>;
export const TransferClientOwnershipDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'TransferClientOwnership' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transfer_ownership' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'new_owner_client_user_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TransferClientOwnershipMutation,
  TransferClientOwnershipMutationVariables
>;
export const UpdateClientDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateClient' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateClientInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_client' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Client_fromUpdateClient' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Client_fromUpdateClient' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Client' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateClientMutation,
  UpdateClientMutationVariables
>;
export const UpsertClientIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertClientIntegration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ClientIntegrationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsert_client_integration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientIntegration_fromUpsertClientIntegration',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ClientIntegration_fromUpsertClientIntegration',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ClientIntegration' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertClientIntegrationMutation,
  UpsertClientIntegrationMutationVariables
>;
export const UploadFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UploadFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'file' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Upload' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'folder' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'S3FolderEnum' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upload_file' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'file' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'file' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'folder' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'folder' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eTag' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UploadFileMutation, UploadFileMutationVariables>;
export const GetCountriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCountries' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'countries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Country_fromGetCountries' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Country_fromGetCountries' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Country' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCountriesQuery, GetCountriesQueryVariables>;
export const ConfigureSupplierEntitlementsForDataCollectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'configureSupplierEntitlementsForDataCollection',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'ConfigureSupplierEntitlementsForDataCollectionInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'configure_supplier_entitlements_for_data_collection',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ConfigureSupplierEntitlementsForDataCollectionMutation,
  ConfigureSupplierEntitlementsForDataCollectionMutationVariables
>;
export const LinkImportToRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'linkImportToRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LinkImportToRequestInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'link_import_to_request' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LinkImportToRequestMutation,
  LinkImportToRequestMutationVariables
>;
export const TransitionDataCollectionRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'transitionDataCollectionRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'TransitionDataCollectionRequestInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transition_data_collection_request' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TransitionDataCollectionRequestMutation,
  TransitionDataCollectionRequestMutationVariables
>;
export const AddInventoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddInventory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AddInventoryInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'add_inventory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddInventoryMutation,
  AddInventoryMutationVariables
>;
export const GetAvailableInventoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAvailableInventory' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'available_inventory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'Inventory_fromGetAvailableInventory',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Inventory_fromGetAvailableInventory' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Inventory' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avoidance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'removal' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'media' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bucket' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'registry' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'registry_retirement_fee_per_tonne',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocated_weight_kg' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price_per_kg_in_gbp' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'vintage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registry' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAvailableInventoryQuery,
  GetAvailableInventoryQueryVariables
>;
export const GetInventoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getInventory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'vintages' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectPublicIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'registryPublicIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sortedColumn' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'InventorySortedColumnEnum' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sortedOrder' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SortedOrderEnum' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'inventory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'size' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'size' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'vintages' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'vintages' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'project_public_ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectPublicIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'registry_public_ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'registryPublicIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sorted_column' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sortedColumn' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sorted_order' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sortedOrder' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'Inventory_fromGetInventory',
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total_count' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Inventory_fromGetInventory' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Inventory' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vintage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registry' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocated_weight_kg' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'retired_weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price_per_kg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price_per_kg_in_gbp' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetInventoryQuery, GetInventoryQueryVariables>;
export const GetInventoryPropertyValuesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getInventoryPropertyValues' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'InventoryFilterPropertiesEnum' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'value' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'inventory_property_values' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'key' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'key' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'value' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'value' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetInventoryPropertyValuesQuery,
  GetInventoryPropertyValuesQueryVariables
>;
export const RemoveInventoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveInventory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RemoveInventoryInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remove_inventory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveInventoryMutation,
  RemoveInventoryMutationVariables
>;
export const GetInventoryEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getInventoryEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'inventory_event' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'InventoryEvent_fromGetInventoryEvent',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InventoryEvent_fromGetInventoryEvent' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InventoryEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'project_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projects' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'project_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vintage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'registry' } },
                { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'vintage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'registry_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registry' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'portfolio_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price_per_kg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price_per_kg_in_gbp' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rrp_min' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rrp_max' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocated_weight_kg' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'retired_weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meta_data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pledge_meta_data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetInventoryEventQuery,
  GetInventoryEventQueryVariables
>;
export const GetInventoryEventsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getInventoryEvents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'vintages' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectPublicIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'eventTypes' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'InventoryEventTypeEnum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sortedColumn' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'InventoryEventsSortedColumnEnum' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sortedOrder' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SortedOrderEnum' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'inventory_events' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'size' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'size' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'vintages' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'vintages' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'project_public_ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectPublicIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'event_types' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'eventTypes' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sorted_column' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sortedColumn' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sorted_order' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sortedOrder' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'InventoryEvent_fromGetInventoryEvents',
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total_count' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InventoryEvent_fromGetInventoryEvents' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InventoryEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'project_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'vintage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'registry_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registry' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'portfolio_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price_per_kg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price_per_kg_in_gbp' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rrp_min' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rrp_max' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocated_weight_kg' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'retired_weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetInventoryEventsQuery,
  GetInventoryEventsQueryVariables
>;
export const GetInventoryEventsPropertyValuesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getInventoryEventsPropertyValues' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'InventoryEventsFilterPropertiesEnum',
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'value' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectPublicIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'inventory_events_property_values' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'key' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'key' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'value' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'value' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'project_public_ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectPublicIds' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetInventoryEventsPropertyValuesQuery,
  GetInventoryEventsPropertyValuesQueryVariables
>;
export const GetOrderCo2eRangeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrderCo2eRange' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'order_co2e_range' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'histogram' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'doc_count' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrderCo2eRangeQuery,
  GetOrderCo2eRangeQueryVariables
>;
export const GetOrderFiltersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrderFilters' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'order_co2e_range' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'histogram' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'doc_count' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'order_metadata_keys' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrderFiltersQuery,
  GetOrderFiltersQueryVariables
>;
export const GetOrderMetadataValuesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrderMetadataValues' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'value' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'order_metadata_values' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'key' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'key' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'value' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'value' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrderMetadataValuesQuery,
  GetOrderMetadataValuesQueryVariables
>;
export const GetOrderPropertyValuesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrderPropertyValues' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'OrderFilterPropertiesEnum' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'value' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'order_property_values' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'key' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'key' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'value' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'value' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrderPropertyValuesQuery,
  GetOrderPropertyValuesQueryVariables
>;
export const GetOrdersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrders' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'publicIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientPublicIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientLegalNames' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'portfolioPublicIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'portfolioNames' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'invoiceNumbers' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'OrderStatusEnum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'co2e' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RangeInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sortedColumn' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'OrderSortedColumnEnum' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sortedOrder' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SortedOrderEnum' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orders' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'size' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'size' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public_ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'publicIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'client_public_ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientPublicIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'client_legal_names' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientLegalNames' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'portfolio_public_ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'portfolioPublicIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'portfolio_names' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'portfolioNames' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invoice_numbers' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'invoiceNumbers' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'status' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'co2e' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'co2e' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sorted_column' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sortedColumn' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sorted_order' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sortedOrder' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Order_fromGetOrders' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total_count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total_co2e' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagination_limit' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Order_fromGetOrders' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Order' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'portfolio_version' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetOrdersQuery, GetOrdersQueryVariables>;
export const GetPortfolioDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPortfolio' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'public_id' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'public_id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'portfolio' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'Portfolio_fromGetPortfolio',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'keyFacts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'KeyFact_fromGetPortfolio',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'projects' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'Project_fromGetPortfolio',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Portfolio_fromGetPortfolio' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Portfolio' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_published' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'display_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'portfolio_version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'prices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'price_per_kg' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocated_weight_kg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reserved_weight_kg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'public_id' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avoidance' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'removal' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'coordinates' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'coordinates' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_type' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'category' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'code' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'media' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'bucket' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'key' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clients' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'distribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalAllocated' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'avoidance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'removal' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'KeyFact_fromGetPortfolio' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PortfolioKeyFact' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key_facts' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Project_fromGetPortfolio' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Project' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registry' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'registry_retirement_fee_per_tonne',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPortfolioQuery, GetPortfolioQueryVariables>;
export const GetPortfolioStatsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPortfolioStats' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio_stats' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'Portfolio_fromGetPortfolioStats',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Portfolio_fromGetPortfolioStats' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PortfolioStats' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'total_orders' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPortfolioStatsQuery,
  GetPortfolioStatsQueryVariables
>;
export const GetPortfoliosDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPortfolios' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderColumn' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PortfolioOrderColumnEnum' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderDirection' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'OrderDirectionEnum' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolios' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'size' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'size' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_column' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderColumn' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_direction' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderDirection' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'PortfolioDataMapper_fromGetPortfolios',
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total_count' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioDataMapper_fromGetPortfolios' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PortfolioDataMapper' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'display_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'portfolio_version' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'prices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'price_per_kg' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocated_weight_kg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reserved_weight_kg' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'is_published' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_draft' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPortfoliosQuery, GetPortfoliosQueryVariables>;
export const PublishPortfolioDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'publishPortfolio' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publish_portfolio' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PublishPortfolioMutation,
  PublishPortfolioMutationVariables
>;
export const UnpublishPortfolioDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'unpublishPortfolio' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unpublish_portfolio' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UnpublishPortfolioMutation,
  UnpublishPortfolioMutationVariables
>;
export const CreatePortfolioDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreatePortfolio' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PortfolioDraftInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'create_portfolio' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePortfolioMutation,
  CreatePortfolioMutationVariables
>;
export const GetPortfolioDraftDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPortfolioDraft' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio_draft' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PortfolioDraft_fromGetPortfolioDraft',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioDraft_fromGetPortfolioDraft' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PortfolioDraft' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio_draft' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'details' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accounting_code' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'key_facts' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'portfolio_version' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allocations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'registry_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'registry' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'weight_tonnes' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vintage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'price_per_kg_in_gbp' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avoidance' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'removal' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'registry_fee_per_tonne' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'acl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPortfolioDraftQuery,
  GetPortfolioDraftQueryVariables
>;
export const UpsertPortfolioDraftDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertPortfolioDraft' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PortfolioDraftInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsert_portfolio_draft' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'PortfolioDraft_fromUpsertPortfolioDraft',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfolioDraft_fromUpsertPortfolioDraft' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PortfolioDraft' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertPortfolioDraftMutation,
  UpsertPortfolioDraftMutationVariables
>;
export const GetProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'public_id' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'public_id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Project_fromGetProject' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Project_fromGetProject' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Project' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avoidance' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'coordinates' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project_type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'developer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'media' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'location' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bucket' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'owner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registry' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'media' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'location' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bucket' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'certifications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'media' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'location' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bucket' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carbonplan_assessment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data_stringify' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sdgs' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eTag' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registry_project_id' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registry_project_url' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'contact' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProjectQuery, GetProjectQueryVariables>;
export const GetProjectPropertyValuesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectPropertyValues' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ProjectFilterPropertiesEnum' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'value' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project_property_values' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'key' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'key' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'value' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'value' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProjectPropertyValuesQuery,
  GetProjectPropertyValuesQueryVariables
>;
export const GetProjectsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjects' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectPublicIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sortedColumn' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ProjectSortedColumnEnum' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sortedOrder' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SortedOrderEnum' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projects' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'project_name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'project_public_ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectPublicIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'size' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'size' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sorted_column' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sortedColumn' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sorted_order' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sortedOrder' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'Project_fromGetProjects',
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total_count' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Project_fromGetProjects' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Project' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project_type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registry' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProjectsQuery, GetProjectsQueryVariables>;
export const UpsertProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertProjectInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsert_project' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Project_fromUpsertProject' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Project_fromUpsertProject' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Project' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertProjectMutation,
  UpsertProjectMutationVariables
>;
export const GetProjectCertificationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectCertification' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project_certification' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProjectCertification_fromProjectCertification',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ProjectCertification_fromProjectCertification',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectCertification' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eTag' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'registry_link' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProjectCertificationQuery,
  GetProjectCertificationQueryVariables
>;
export const GetProjectCertificationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectCertifications' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project_certifications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProjectCertification_fromGetProjectCertifications',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ProjectCertification_fromGetProjectCertifications',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectCertification' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eTag' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProjectCertificationsQuery,
  GetProjectCertificationsQueryVariables
>;
export const UpsertProjectCertificationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertProjectCertification' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertProjectCertificationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsert_project_certification' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'ProjectCertification_fromUpsertProjectCertification',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ProjectCertification_fromUpsertProjectCertification',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectCertification' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertProjectCertificationMutation,
  UpsertProjectCertificationMutationVariables
>;
export const GetProjectDeveloperDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectDeveloper' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'public_id' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project_developer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'public_id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProjectDeveloper_fromGetProjectDeveloper',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectDeveloper_fromGetProjectDeveloper' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectDeveloper' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projects' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project_type' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'category' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'country' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eTag' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProjectDeveloperQuery,
  GetProjectDeveloperQueryVariables
>;
export const GetProjectDevelopersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectDevelopers' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project_developers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProjectDeveloper_fromGetProjectDevelopers',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ProjectDeveloper_fromGetProjectDevelopers',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectDeveloper' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projects' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProjectDevelopersQuery,
  GetProjectDevelopersQueryVariables
>;
export const GetProjectDevelopersNameIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectDevelopersNameId' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project_developers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProjectDeveloperNameId_fromGetProjectDevelopers',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ProjectDeveloperNameId_fromGetProjectDevelopers',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectDeveloper' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProjectDevelopersNameIdQuery,
  GetProjectDevelopersNameIdQueryVariables
>;
export const UpsertProjectDeveloperDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertProjectDeveloper' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertDeveloperInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsert_project_developer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProjectDeveloper_fromUpsertProjectDeveloper',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ProjectDeveloper_fromUpsertProjectDeveloper',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectDeveloper' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertProjectDeveloperMutation,
  UpsertProjectDeveloperMutationVariables
>;
export const GetProjectOwnerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectOwner' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project_owner' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProjectOwner_fromGetProjectOwner',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectOwner_fromGetProjectOwner' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectOwner' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProjectOwnerQuery,
  GetProjectOwnerQueryVariables
>;
export const GetProjectOwnersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectOwners' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project_owners' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProjectOwner_fromGetProjectOwners',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectOwner_fromGetProjectOwners' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectOwner' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProjectOwnersQuery,
  GetProjectOwnersQueryVariables
>;
export const UpsertProjectOwnerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertProjectOwner' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ProjectOwnerInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsert_project_owner' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProjectOwner_fromUpsertProjectOwner',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectOwner_fromUpsertProjectOwner' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectOwner' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertProjectOwnerMutation,
  UpsertProjectOwnerMutationVariables
>;
export const GetProjectTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project_type' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProjectType_fromGetProjectType',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectType_fromGetProjectType' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProjectTypeQuery, GetProjectTypeQueryVariables>;
export const GetProjectTypeCategoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectTypeCategories' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project_type_categories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProjectTypeCategoriesQuery,
  GetProjectTypeCategoriesQueryVariables
>;
export const GetProjectTypesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectTypes' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project_types' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProjectType_fromGetProjectTypes',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectType_fromGetProjectTypes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProjectTypesQuery,
  GetProjectTypesQueryVariables
>;
export const UpsertProjectTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertProjectType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ProjectTypeInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsert_project_type' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProjectType_fromUpsertProjectType',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectType_fromUpsertProjectType' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProjectType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertProjectTypeMutation,
  UpsertProjectTypeMutationVariables
>;
export const GetRegistriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getRegistries' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Registry_fromGetRegistries' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Registry_fromGetRegistries' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Registry' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registry_retirement_fee_per_tonne' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eTag' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetRegistriesQuery, GetRegistriesQueryVariables>;
export const GetRegistryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getRegistry' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'public_id' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registry' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'public_id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Registry_fromGetRegistry' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Registry_fromGetRegistry' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Registry' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'infrastructure' } },
          { kind: 'Field', name: { kind: 'Name', value: 'projects_base_url' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'has_automated_retirement' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registry_retirement_fee_per_tonne' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eTag' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetRegistryQuery, GetRegistryQueryVariables>;
export const UpsertRegistryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertRegistry' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RegistryInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsert_registry' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Registry_fromUpsertRegistry' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Registry_fromUpsertRegistry' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Registry' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertRegistryMutation,
  UpsertRegistryMutationVariables
>;
export const ConfirmRetirementDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ConfirmRetirement' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ConfirmRetirementInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'confirm_retirement' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ConfirmRetirementMutation,
  ConfirmRetirementMutationVariables
>;
export const GetRetirementsConfirmedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getRetirementsConfirmed' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sortedColumn' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RetirementSortedColumnEnum' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sortedOrder' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SortedOrderEnum' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'retirements_confirmed' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'size' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'size' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sorted_column' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sortedColumn' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sorted_order' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sortedOrder' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value:
                            'RetirementConfirmed_fromGetRetirementsConfirmed',
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total_count' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RetirementConfirmed_fromGetRetirementsConfirmed',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RetirementConfirmed' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'vintage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registry' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'retirement_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'retirement_url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'serial_numbers' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetRetirementsConfirmedQuery,
  GetRetirementsConfirmedQueryVariables
>;
export const GetRetirementsRequestedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getRetirementsRequested' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'retirements_requested' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'RetirementRequested_fromGetRetirementsRequested',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'RetirementRequested_fromGetRetirementsRequested',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RetirementRequested' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registry' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'retirement_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vintage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetRetirementsRequestedQuery,
  GetRetirementsRequestedQueryVariables
>;
export const MigrateClientsToChargebeeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'migrateClientsToChargebee' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'migrate_clients_to_chargebee' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MigrateClientsToChargebeeMutation,
  MigrateClientsToChargebeeMutationVariables
>;
export const ReconcileInventoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'reconcileInventory' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reconcile_inventory' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ReconcileInventoryMutation,
  ReconcileInventoryMutationVariables
>;
export const SyncPortfoliosInChargebeeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'syncPortfoliosInChargebee' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sync_portfolios_in_chargebee' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SyncPortfoliosInChargebeeMutation,
  SyncPortfoliosInChargebeeMutationVariables
>;
export const TriggerNetlifyDeployDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'triggerNetlifyDeploy' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'trigger_netlify_deploy' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TriggerNetlifyDeployMutation,
  TriggerNetlifyDeployMutationVariables
>;
export const GetMeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMe' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User_fromMe' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User_fromMe' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'sub' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email_verified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetMeQuery, GetMeQueryVariables>;
