import classNames from 'classnames';

import { UserMenu } from './UserMenu';
import './TopBar.scss';

export function TopBar(props: { sandboxEnabled?: boolean }) {
  return (
    <div>
      <div
        className={classNames('TopBar', {
          'TopBar__test-mode': props.sandboxEnabled,
        })}
      >
        <UserMenu />
      </div>
    </div>
  );
}
