import { FormattedMessage } from 'react-intl';
import {
  CategoryFilledIcon,
  DashboardIcon,
  FileSpreadsheetIcon,
  ImageIcon,
  ImagesIcon,
  ListChecksIcon,
  OffsetsIcon,
  PeopleIcon,
  ProjectCertificateIcon,
  ProjectDeveloperIcon,
  ProjectOwnerIcon,
  ToolboxIcon,
} from '@pledge-earth/product-language';

import { DotIcon } from '../../components/SideBarSVG/DotIcon';

export interface MenuItem {
  title: React.ReactNode;
  icon: React.ReactNode;
  suffixIcon?: React.ReactNode;
  key: string;
  url?: string | undefined;
  position?: 'top' | 'bottom';
  sub_urls?: string[];
  children?: MenuItem[];
}

// eslint-disable-next-line @typescript-eslint/require-await -- eslint onboarding
export async function getMenuData(): Promise<MenuItem[]> {
  return [
    {
      title: <FormattedMessage id="leftMenu.home" />,
      icon: <DashboardIcon />,
      key: '__dashboard',
      url: '/dashboard',
      position: 'top',
    },
    {
      title: <FormattedMessage id="leftMenu.clients" />,
      icon: <PeopleIcon />,
      key: '__clients',
      url: '/clients',
      sub_urls: ['/clients/add', '/client/'],
      position: 'top',
    },
    {
      title: <FormattedMessage id="leftMenu.offsetting" />,
      icon: <OffsetsIcon />,
      children: [
        {
          title: <FormattedMessage id="leftMenu.orders" />,
          key: '__orders',
          url: '/orders',
          icon: <DotIcon />,
        },
        {
          title: <FormattedMessage id="leftMenu.portfolios" />,
          key: '__portfolios',
          url: '/offsetting/portfolios',
          icon: <ImagesIcon />,
        },
        {
          title: <FormattedMessage id="leftMenu.projects" />,
          key: '__projects',
          url: '/offsetting/projects',
          icon: <ImageIcon />,
        },
        {
          title: <FormattedMessage id="leftMenu.projectDevelopers" />,
          key: '__project_developers',
          url: '/offsetting/project-developers',
          icon: <ProjectDeveloperIcon />,
        },
        {
          title: <FormattedMessage id="leftMenu.projectOwners" />,
          key: '__project_owners',
          url: '/offsetting/project-owners',
          icon: <ProjectOwnerIcon />,
        },
        {
          title: <FormattedMessage id="leftMenu.projectTypes" />,
          key: '__project_types',
          url: '/offsetting/project-types',
          icon: <CategoryFilledIcon />,
        },
        {
          title: <FormattedMessage id="leftMenu.projectCertifications" />,
          key: '__project_certifications',
          url: '/offsetting/project-certifications',
          icon: <ProjectCertificateIcon />,
        },
        {
          title: <FormattedMessage id="leftMenu.inventory" />,
          key: '__inventory',
          url: '/offsetting/inventory',
          sub_urls: ['/inventory/add', '/inventory/'],
          icon: <DotIcon />,
        },
        {
          title: <FormattedMessage id="leftMenu.inventoryReconciliation" />,
          key: '__inventory_reconciliation',
          url: '/offsetting/inventoryReconciliation',
          icon: <DotIcon />,
        },
        {
          title: <FormattedMessage id="leftMenu.retirements" />,
          key: '__retirements',
          url: '/offsetting/retirements',
          icon: <DotIcon />,
        },
        {
          title: <FormattedMessage id="leftMenu.retirements_pending" />,
          key: '__retirements_pending"',
          url: '/offsetting/retirements/pending',
          icon: <DotIcon />,
        },
        {
          title: <FormattedMessage id="leftMenu.registries" />,
          key: '__registries',
          url: '/offsetting/registries',
          icon: <FileSpreadsheetIcon />,
        },
      ],
      key: '__offsetting',
      position: 'top',
    },
    {
      title: <FormattedMessage id="leftMenu.data-collection" />,
      icon: <ListChecksIcon />,
      children: [
        {
          title: (
            <FormattedMessage id="leftMenu.data-collection.configure-supplier-entitlements" />
          ),
          key: '__configure_supplier_entitlements',
          url: '/data-collection/configure-supplier-entitlements',
          icon: <DotIcon />,
        },
        {
          title: <FormattedMessage id="leftMenu.data-collection.link-import" />,
          key: '__link_import',
          url: '/data-collection/link-import',
          icon: <DotIcon />,
        },
        {
          title: (
            <FormattedMessage id="leftMenu.data-collection.submit-request-data" />
          ),
          key: '__submit_request_data',
          url: '/data-collection/submit-request-data',
          icon: <DotIcon />,
        },
      ],
      key: '__data_collection',
      position: 'top',
    },
    {
      title: <FormattedMessage id="leftMenu.tools" />,
      icon: <ToolboxIcon />,
      key: '__tools',
      url: '/tools',
      position: 'top',
    },
  ];
}
