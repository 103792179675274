import { FormattedMessage, useIntl } from 'react-intl';
import {
  Heading,
  Size,
  Form,
  FormSection,
  FormFieldGroup,
  FormSectionHeader,
} from '@pledge-earth/product-language';
import type { UseFormReturn } from 'react-hook-form';
import { useWatch } from 'react-hook-form';

import type { Maybe } from '../../../services/graphql/generated';
import type { FormData } from '../PortfolioEdit';
import { TextFieldControlled } from '../../../components/ReactHookForm/TextFieldControlled';
import { FormErrors } from '../../../components/ReactHookForm/FormErrors';
import { TextAreaFieldControlled } from '../../../components/ReactHookForm/TextAreaFieldControlled';

export function PortfolioEditDetails({
  formId,
  clientName,
  formMethods,
}: {
  formId: string;
  clientName?: Maybe<string> | undefined;
  formMethods: UseFormReturn<FormData>;
}) {
  const { formatMessage } = useIntl();
  const { control, formState } = formMethods;
  const portfolioDraft = useWatch({
    control,
    name: 'portfolio_draft',
  });

  return (
    <Form id={formId} noValidate={true} aria-label="Edit portfolio">
      <FormErrors formState={formState} />

      <FormSection>
        <FormSectionHeader>
          <Heading>
            <FormattedMessage id="portfolio.edit.details.name" />
          </Heading>
        </FormSectionHeader>

        {portfolioDraft.details?.name ? (
          <Heading level="h4">
            {clientName ? `${clientName} ` : null}
            {portfolioDraft.details?.name}{' '}
            {portfolioDraft.details?.portfolio_version}
          </Heading>
        ) : null}

        <FormFieldGroup layout="horizontal">
          <TextFieldControlled
            control={control}
            name="portfolio_draft.details.name"
            label={<FormattedMessage id="portfolio.edit.name" />}
            size={Size.Loose}
            placeholder={formatMessage({
              id: 'portfolio.edit.name.placeholder',
            })}
          />

          <TextFieldControlled
            control={control}
            name="portfolio_draft.details.portfolio_version"
            label={<FormattedMessage id="portfolio.edit.portfolio_version" />}
            size={Size.Loose}
            placeholder={formatMessage({
              id: 'portfolio.edit.portfolio_version.placeholder',
            })}
          />
        </FormFieldGroup>
      </FormSection>

      <FormSection>
        <Heading level="h5">Details</Heading>
        <TextFieldControlled
          control={control}
          name="portfolio_draft.details.key_facts.0"
          size={Size.Loose}
          label={<FormattedMessage id="portfolio.edit.key_fact_ids" />}
          placeholder={formatMessage({
            id: 'portfolio.edit.key_fact_ids.placeholder',
          })}
        />

        <TextFieldControlled
          name="portfolio_draft.details.key_facts.1"
          control={control}
          placeholder={formatMessage({
            id: 'portfolio.edit.key_fact_ids.placeholder',
          })}
        />
        <TextFieldControlled
          name="portfolio_draft.details.key_facts.2"
          control={control}
          size={Size.Loose}
          placeholder={formatMessage({
            id: 'portfolio.edit.key_fact_ids.placeholder',
          })}
        />
        <TextFieldControlled
          name="portfolio_draft.details.key_facts.3"
          control={control}
          size={Size.Loose}
          placeholder={formatMessage({
            id: 'portfolio.edit.key_fact_ids.placeholder',
          })}
        />

        <TextAreaFieldControlled
          control={control}
          name="portfolio_draft.details.description"
          label={<FormattedMessage id="portfolio.edit.description" />}
          rows={4}
          placeholder={formatMessage({
            id: 'portfolio.edit.description.placeholder',
          })}
        />
      </FormSection>
    </Form>
  );
}
