import type { TextAreaFieldProps } from '@pledge-earth/product-language';
import { TextAreaField } from '@pledge-earth/product-language';
import {
  Controller,
  type ControllerProps,
  type FieldPath,
  type FieldValues,
} from 'react-hook-form';

type Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<ControllerProps<TFieldValues, TName>, 'render'> &
  Omit<
    TextAreaFieldProps,
    | 'ref'
    | 'name'
    | 'value'
    | 'onChange'
    | 'onBlur'
    | 'validationBehavior'
    | 'isInvalid'
    | 'errorMessage'
    | 'isDisabled'
  >;

/**
 * A PPL TextAreaField wrapped in a React Hook Form Controller
 */
export function TextAreaFieldControlled<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  defaultValue,
  rules,
  shouldUnregister,
  disabled,
  ...rest
}: Props<TFieldValues, TName>) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      shouldUnregister={shouldUnregister}
      disabled={disabled}
      render={({
        field: { name, value, onChange, onBlur, ref, disabled },
        fieldState: { invalid, error },
      }) => (
        <TextAreaField
          ref={ref}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          isDisabled={disabled}
          // Let React Hook Form handle validation instead of the browser.
          validationBehavior="aria"
          isInvalid={invalid}
          errorMessage={error?.message}
          // Forward remaining props to TextField.
          {...rest}
        />
      )}
    />
  );
}
