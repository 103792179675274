import {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
} from 'react';
import { debounce, get } from 'lodash';
import { twMerge } from '@pledge-earth/product-language';

import type { S3Object } from '../../services/graphql/generated';

interface S3BasedImageProps {
  className?: string;
  ratio?: number;
  s3Object: S3Object;
  calculateWidthFromHeight?: boolean;
  alt?: string;
  smartCropOptions?: {
    padding?: number;
    faceIndex?: number;
  };
}

const { REACT_APP_IMAGES_CDN_URL } = process.env;

const CDN_URL = `https://${REACT_APP_IMAGES_CDN_URL?.replace(/^https?:\/\//, '')}`;

function ImageDisplay({
  alt,
  imageUrl,
  className,
}: {
  alt: string;
  imageUrl: string;
  className?: string;
}) {
  const [hasError, setHasError] = useState(false);
  const [loaded, setLoaded] = useState(false);

  return (
    <div className="relative w-full h-full bg-no-repeat bg-center bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTQuNSAyLjVoLTEzQS41LjUgMCAwIDAgMSAzdjEwYS41LjUgMCAwIDAgLjUuNWgxM2EuNS41IDAgMCAwIC41LS41VjNhLjUuNSAwIDAgMC0uNS0uNXpNNS4yODEgNC43NWExIDEgMCAwIDEgMCAyIDEgMSAwIDAgMSAwLTJ6bTguMDMgNi44M2EuMTI3LjEyNyAwIDAgMS0uMDgxLjAzSDIuNzY5YS4xMjUuMTI1IDAgMCAxLS4wOTYtLjIwN2wyLjY2MS0zLjE1NmEuMTI2LjEyNiAwIDAgMSAuMTc3LS4wMTZsLjAxNi4wMTZMNy4wOCAxMC4wOWwyLjQ3LTIuOTNhLjEyNi4xMjYgMCAwIDEgLjE3Ny0uMDE2bC4wMTUuMDE2IDMuNTg4IDQuMjQ0YS4xMjcuMTI3IDAgMCAxLS4wMi4xNzV6IiBmaWxsPSIjOEM4QzhDIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L3N2Zz4=')]">
      {!hasError && (
        <img
          alt={alt}
          src={imageUrl}
          className={twMerge(
            'w-full h-full object-contain',
            loaded ? 'block' : 'hidden',
            className,
          )}
          onLoad={() => setLoaded(true)}
          onError={() => setHasError(true)}
        />
      )}
    </div>
  );
}

export const generateCDNUrl = ({
  width,
  height,
  s3Object,
  smartCropOptions,
}: {
  width: number | undefined | null;
  height: number | undefined | null;
  s3Object: S3Object;
  smartCropOptions?: {
    padding?: number;
    faceIndex?: number;
  };
  resizeOptions?: {
    height?: number;
    width?: number;
    fit?: 'cover' | 'contain' | 'fill' | 'inside' | 'outside';
  };
}) => {
  if (width === 0 || height === 0) {
    return '';
  }

  if (!s3Object?.bucket || !s3Object.key) {
    return '';
  }

  const { bucket, key } = s3Object;
  const pixelRatio = window.devicePixelRatio;

  const request = {
    key,
    bucket,
    edits: {
      resize: {
        ...(width ? { width: width * pixelRatio } : {}),
        ...(height ? { height: height * pixelRatio } : {}),
      },
      ...(smartCropOptions ? { smartCrop: smartCropOptions } : {}),
    },
  };

  const str = JSON.stringify(request);
  const enc = btoa(str);
  return `${CDN_URL}/${enc}`;
};

export function ImageS3({
  s3Object,
  ratio = 16 / 9,
  smartCropOptions,
  calculateWidthFromHeight,
  alt = '',
  className,
}: S3BasedImageProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [imageUrl, setImageUrl] = useState('');
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [widthPrev, setWidthPrev] = useState(0);
  const [heightPrev, setHeightPrev] = useState(0);

  const adjustImageUrl = useCallback(
    (props: {
      width: number;
      height: number;
      s3Object: S3Object;
      smartCropOptions?: {
        padding?: number | undefined;
        faceIndex?: number | undefined;
      };
    }) => {
      setImageUrl(generateCDNUrl(props));

      setWidthPrev(props.width);

      setHeightPrev(props.height);
    },
    [],
  );

  const debouncedAdjustImageUrl = useMemo(
    () => debounce(adjustImageUrl, 200),
    [adjustImageUrl],
  );

  const calculateImageSize = useCallback(() => {
    if (containerRef.current) {
      if (calculateWidthFromHeight) {
        const offsetHeight = get(
          containerRef,
          'current.parentElement.offsetHeight',
        );
        if (offsetHeight) {
          setWidth(Math.round(offsetHeight * ratio));
          setHeight(Math.round(offsetHeight));
        }
      } else {
        const { offsetWidth } = containerRef.current;
        setWidth(Math.round(offsetWidth));
        setHeight(Math.round(offsetWidth / ratio));
      }
    }
  }, [calculateWidthFromHeight, ratio, containerRef]);

  useLayoutEffect(() => {
    // calculate the image size after mounting
    if (width === 0) {
      calculateImageSize();
    }
  });

  useEffect(() => {
    if (width > widthPrev || height > heightPrev) {
      const props = { width, height, s3Object, smartCropOptions };

      if (imageUrl) {
        // use debounced version as imageUrl is already set and window might be resizing
        debouncedAdjustImageUrl(props);
      } else {
        adjustImageUrl(props);
      }
    }
  }, [
    width,
    height,
    s3Object,
    imageUrl,
    widthPrev,
    heightPrev,
    adjustImageUrl,
    smartCropOptions,
    debouncedAdjustImageUrl,
  ]);

  useEffect(() => {
    // recalculate when window resizes
    window.addEventListener('resize', calculateImageSize);

    return () => {
      window.removeEventListener('resize', calculateImageSize);
    };
  }, [calculateImageSize]);

  const style = {
    width: width ? `${width}px` : '100%',
    height: height ? `${height}px` : '100%',
  };

  return (
    <div className="relative" ref={containerRef} style={style}>
      <ImageDisplay alt={alt} imageUrl={imageUrl} className={className} />
    </div>
  );
}

export function ImageS3Resized({
  width,
  height,
  s3Object,
  alt = '',
  className,
}: {
  // If you specify both width and height, the image will be resized to fit within the specified dimensions which will possibly distort the image.
  // If you specify only one of the dimensions, the image will be resized to fit within the specified dimension while maintaining the original aspect ratio.
  width?: number;
  height?: number;
  s3Object: S3Object;
  alt?: string;
  className?: string;
}) {
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    setImageUrl(generateCDNUrl({ s3Object, width, height }));
  }, [s3Object, width, height]);

  const style = {
    width: width ? `${width}px` : '100%',
    height: height ? `${height}px` : '100%',
  };

  return (
    <div style={style}>
      <ImageDisplay alt={alt} imageUrl={imageUrl} className={className} />
    </div>
  );
}
