import { useLazyQuery, type TypedDocumentNode } from '@apollo/client';
import type { SelectProps } from '@pledge-earth/web-components';
import {
  // eslint-disable-next-line no-restricted-imports -- blocked by PPL Combobox
  Select,
} from '@pledge-earth/web-components';
import { debounce } from 'lodash';
import type { ForwardedRef } from 'react';
import { useEffect, useMemo } from 'react';
import { ChevronUpDownMinorIcon } from '@pledge-earth/product-language';

import { genericForwardRef } from '../../../utils/genericForwardRef';

import './MultiSelectAutocomplete.scss';

type InferQueryTypes<T> =
  T extends TypedDocumentNode<infer TResult, infer TVariables>
    ? { result: TResult; variables: TVariables }
    : never;

export type MultiSelectAutocompleteProps<
  TQuery extends TypedDocumentNode<any, any>,
> = {
  queryDocument: TQuery;
  queryVariables: Omit<InferQueryTypes<TQuery>['variables'], 'value'>;
  renderOptions: (data: InferQueryTypes<TQuery>['result']) => React.ReactNode;
} & Omit<
  SelectProps<any, any>,
  | 'className'
  | 'onSearch'
  | 'onFocus'
  | 'onSelect'
  | 'loading'
  | 'mode'
  | 'optionLabelProp'
  | 'showArrow'
  | 'showSearch'
  | 'allowClear'
  | 'maxTagCount'
  | 'suffixIcon'
>;

/**
 * Select component with support for lazy-loading options from a provided query.
 */
export const MultiSelectAutocomplete = /* #__PURE__ */ genericForwardRef(
  // eslint-disable-next-line prefer-arrow-callback
  function MultiSelectAutocomplete<TQuery extends TypedDocumentNode<any, any>>(
    {
      queryDocument,
      queryVariables,
      renderOptions,
      ...selectProps
    }: MultiSelectAutocompleteProps<TQuery>,
    forwardedRef: ForwardedRef<any>,
  ) {
    const [getValues, { data, loading, called }] = useLazyQuery(queryDocument);

    const handleSearch = useMemo(
      () =>
        debounce(
          (value: string) =>
            getValues({ variables: { ...queryVariables, value } }),
          300,
          { leading: true },
        ),
      [getValues, queryVariables],
    );

    useEffect(() => {
      // If the query hasn't been called yet but there are selected values, fetch the options. This is useful because the
      // fetched options may have different labels than the selected values.
      if (
        !called &&
        Array.isArray(selectProps.value) &&
        selectProps.value.length > 0
      ) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        getValues({ variables: { ...queryVariables, value: '' } });
      }
    }, [called, getValues, queryVariables, selectProps.value]);

    return (
      <Select
        {...selectProps}
        ref={forwardedRef}
        className="MultiSelectAutocomplete"
        onSearch={handleSearch}
        onFocus={() => handleSearch('')}
        loading={loading}
        mode="multiple"
        optionLabelProp="label"
        showArrow={true}
        showSearch={true}
        allowClear={true}
        maxTagCount="responsive"
        suffixIcon={<ChevronUpDownMinorIcon />}
      >
        {data ? renderOptions(data) : null}
      </Select>
    );
  },
);
