import type { ControllerProps, FieldPath, FieldValues } from 'react-hook-form';
import { Controller } from 'react-hook-form';

import type { ImageUploadProps } from '../UploadFile/ImageUpload';
import { ImageUpload } from '../UploadFile/ImageUpload';

type Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<ControllerProps<TFieldValues, TName>, 'render'> &
  Omit<ImageUploadProps, 'ref' | 'value' | 'onChange'>;

/** a ImageUpload wrapped in a controller */
export function ImageUploadControlled<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  defaultValue,
  rules,
  shouldUnregister,
  disabled,
  ...rest
}: Props<TFieldValues, TName>) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      shouldUnregister={shouldUnregister}
      disabled={disabled}
      render={() => (
        <ImageUpload
          // Forward remaining props to ImageUpload.
          {...rest}
        />
      )}
    />
  );
}
