import { get, map, omit } from 'lodash';
import { useCallback, useId, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Size,
  Dialog,
  DialogHeader,
  DialogTitle,
  OverlayCloseButton,
  DialogBody,
  DialogFooter,
  Form,
  FormSection,
} from '@pledge-earth/product-language';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';

import type {
  GetProjectCertificationQuery,
  ProjectCertification,
  S3Object,
  S3ObjectInput,
} from '../../services/graphql/generated';
import {
  S3FolderEnum,
  UpsertProjectCertificationDocument,
} from '../../services/graphql/generated';
import { EntityAdded } from '../../components/AddEntity/EntityAdded/EntityAdded';
import { showSuccessToast } from '../../utils/toast';
import { defaultUseFormProps } from '../../components/ReactHookForm/defaultUseFormProps';
import { useRootFormError } from '../../components/ReactHookForm/useRootFormError';
import { FormErrors } from '../../components/ReactHookForm/FormErrors';
import { TextFieldControlled } from '../../components/ReactHookForm/TextFieldControlled';
import { TextAreaFieldControlled } from '../../components/ReactHookForm/TextAreaFieldControlled';
import { ImageUploadControlled } from '../../components/ReactHookForm/ImageUploadControlled';

interface FormData {
  description: string | null;
  id: string;
  media: S3Object | null;
  name: string;
  registry_link: string;
}

export function ProjectCertificationEdit({
  closeModal,
  onSaved,
  certification = {} as ProjectCertification,
}: {
  closeModal: () => void;
  certification?: GetProjectCertificationQuery['project_certification'];
  onSaved?: () => void;
}) {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const formId = useId();
  const [logoObj, setLogoObj] = useState<S3ObjectInput | null>(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [createdProjectCertificationId, setCreatedProjectCertificationId] =
    useState<string>();

  const [upsertProjectCertificationMutation, { loading, error }] = useMutation(
    UpsertProjectCertificationDocument,
  );

  const { control, handleSubmit, formState } = useForm<FormData>({
    ...defaultUseFormProps,
    defaultValues: {
      id: certification.id,
      description: certification.description,
      media: certification.media?.[0],
      name: certification.name,
      registry_link: certification.registry_link,
    },
    errors: useRootFormError(error),
  });

  const submit = useCallback(
    async (values: FormData) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention -- eslint onboarding
      const { name, description, registry_link } = values;
      const media = logoObj ? [logoObj] : certification.media;

      const data: any = omit(
        {
          ...certification,
          name,
          description,
          registry_link,
          media: map(media, (o) => omit(o, ['__typename'])),
        },
        ['updated_date', '__typename'],
      );

      const result = await upsertProjectCertificationMutation({
        variables: {
          data,
        },
      });

      const projectCertificationId =
        result.data?.upsert_project_certification?.id;

      if (onSaved) {
        showSuccessToast({
          description: 'Project certification has been updated',
        });
        onSaved();
      } else {
        setCreatedProjectCertificationId(projectCertificationId);
        setShowSuccess(true);
      }
    },
    [onSaved, certification, logoObj, upsertProjectCertificationMutation],
  );

  const handleLogoUpload = useCallback(
    (file: S3Object | null) => {
      if (file) {
        setLogoObj({
          location: file.location,
          eTag: file.eTag,
          bucket: file.bucket,
          key: file.key,
        });
      } else {
        setLogoObj(null);
      }
    },
    [setLogoObj],
  );

  const handleClose = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const redirectToProjectCertificationDetailsPage = useCallback(() => {
    if (createdProjectCertificationId) {
      navigate(
        `/offsetting/project-certifications/${createdProjectCertificationId}`,
      );
    }
    closeModal();
  }, [createdProjectCertificationId, closeModal, navigate]);

  return (
    <Dialog>
      <DialogHeader>
        <DialogTitle>
          {get(certification, 'id') ? (
            <FormattedMessage id="edit-project-certification.title" />
          ) : (
            <FormattedMessage id="project-certification.add.title" />
          )}
        </DialogTitle>
        <OverlayCloseButton label={formatMessage({ id: 'close' })} />
      </DialogHeader>

      <DialogBody>
        {showSuccess ? (
          <EntityAdded
            closeModal={handleClose}
            entityAddedTitle="Project Certification Added"
            actionButtonCTAText="View Project Certification"
            actionButtonCTA={redirectToProjectCertificationDetailsPage}
          />
        ) : null}
        {!showSuccess && (
          <Form
            id={formId}
            aria-labelledby={
              get(certification, 'id')
                ? formatMessage({ id: 'edit-project-certification.title' })
                : formatMessage({ id: 'project-certification.add.title' })
            }
            noValidate={true}
            onSubmit={handleSubmit(submit)}
          >
            <FormErrors formState={formState} />

            <FormSection>
              <ImageUploadControlled
                control={control}
                name="media"
                title={formatMessage({
                  id: 'project-certification.edit.upload_logo',
                })}
                folder={S3FolderEnum.ProjectCertification}
                onUploadImage={handleLogoUpload}
                logo={certification?.media?.[0] as any}
              />

              <TextFieldControlled
                control={control}
                name="name"
                label={formatMessage({
                  id: 'project-certification.edit.name',
                })}
                size={Size.Loose}
                rules={{
                  required: {
                    value: true,
                    message: formatMessage({
                      id: 'project-certification.edit.name.required',
                    }),
                  },
                }}
              />

              <TextAreaFieldControlled
                control={control}
                name="description"
                label={formatMessage({
                  id: 'project-certification.edit.description',
                })}
              />

              <TextFieldControlled
                control={control}
                name="registry_link"
                label={formatMessage({
                  id: 'project-certification.edit.registry_link',
                })}
                size={Size.Loose}
                rules={{
                  required: {
                    value: true,
                    message: formatMessage({
                      id: 'project-certification.edit.registry_link.required',
                    }),
                  },
                  pattern: {
                    value:
                      /(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*))/,
                    message: formatMessage({
                      id: 'project-certification.edit.registry_link.url',
                    }),
                  },
                }}
              />
            </FormSection>
          </Form>
        )}
      </DialogBody>

      {!showSuccess && (
        <DialogFooter>
          <Button onPress={closeModal}>
            <FormattedMessage id="cancel" />
          </Button>
          <Button
            variant="primary"
            type="submit"
            form={formId}
            isLoading={loading}
          >
            <FormattedMessage id="save" />
          </Button>
        </DialogFooter>
      )}
    </Dialog>
  );
}
