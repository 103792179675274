import { useCallback, useId, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  FormSection,
  OverlayCloseButton,
  Size,
} from '@pledge-earth/product-language';
import type { S3Object } from '@pledge-earth/web-components';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';

import type {
  UpsertProjectDeveloperMutationVariables,
  S3ObjectInput,
  GetProjectDeveloperQuery,
} from '../../../services/graphql/generated';
import {
  S3FolderEnum,
  UpsertProjectDeveloperDocument,
} from '../../../services/graphql/generated';
import { EntityAdded } from '../EntityAdded/EntityAdded';
import { showSuccessToast } from '../../../utils/toast';
import { defaultUseFormProps } from '../../ReactHookForm/defaultUseFormProps';
import { FormErrors } from '../../ReactHookForm/FormErrors';
import { ImageUploadControlled } from '../../ReactHookForm/ImageUploadControlled';
import { TextFieldControlled } from '../../ReactHookForm/TextFieldControlled';
import { useRootFormError } from '../../ReactHookForm/useRootFormError';

interface FormData {
  name: string;
  description: string;
  website: string;
  logo: S3Object;
}

export function AddDeveloper({
  closeModal,
  developerDetails,
  onSave,
}: {
  closeModal: () => void;
  developerDetails?: GetProjectDeveloperQuery['project_developer'];
  onSave?: () => void;
}) {
  const navigate = useNavigate();
  const [logoObj, setLogoObj] = useState<S3ObjectInput | null>(null);
  const [developerAdded, setDeveloperAdded] = useState<boolean>(false);
  const [createdDeveloperId, setCreatedDeveloperId] = useState<string>();
  const { formatMessage } = useIntl();
  const [
    upsertProjectDeveloperMutation,
    { loading: upsertProjectDeveloperLoading, error },
  ] = useMutation(UpsertProjectDeveloperDocument);

  const formId = useId();

  const handleLogoUpload = useCallback(
    (file: S3Object | null) => {
      if (file) {
        setLogoObj({
          location: file.location,
          eTag: file.eTag,
          bucket: file.bucket,
          key: file.key,
        });
      } else {
        setLogoObj(null);
      }
    },
    [setLogoObj],
  );

  const submitAddDeveloper = useCallback(
    async (inputData: {
      name: string;
      description: string;
      website: string;
    }) => {
      const payload: UpsertProjectDeveloperMutationVariables['data'] = {
        id: developerDetails?.id ?? undefined,
        name: inputData.name,
        description: inputData.description,
        website: inputData.website,
      };
      payload.logo = logoObj;
      const result = await upsertProjectDeveloperMutation({
        variables: {
          data: payload,
        },
      });

      const projectId = result.data?.upsert_project_developer.id;
      setCreatedDeveloperId(projectId);

      setDeveloperAdded(true);

      if (onSave !== undefined) {
        onSave();
      }

      showSuccessToast({
        description: 'Project developer has been updated',
      });
    },
    [developerDetails?.id, logoObj, onSave, upsertProjectDeveloperMutation],
  );

  const redirectToDeveloperDetailsPage = useCallback(() => {
    if (createdDeveloperId) {
      navigate(`/offsetting/projects/${createdDeveloperId}`);
    }
    closeModal();
  }, [createdDeveloperId, closeModal, navigate]);

  const { name, description, website } = developerDetails ?? {};

  const { control, formState, handleSubmit } = useForm<FormData>({
    ...defaultUseFormProps,
    errors: useRootFormError(error),
    defaultValues: {
      name: name ?? '',
      description: description ?? '',
      website: website ?? '',
    },
  });

  const handleFormSubmit = handleSubmit((values) => submitAddDeveloper(values));

  return (
    <Dialog>
      <DialogHeader>
        <DialogTitle id="add-developer-heading">
          {developerDetails ? (
            <FormattedMessage id="edit-developer.title" />
          ) : (
            <FormattedMessage id="add-developer.title" />
          )}
        </DialogTitle>
        <OverlayCloseButton label={formatMessage({ id: 'close' })} />
      </DialogHeader>

      <DialogBody>
        {developerAdded ? (
          <EntityAdded
            closeModal={closeModal}
            entityAddedTitle={formatMessage({
              id: 'add-developer.developer_saved',
            })}
            actionButtonCTAText={formatMessage({
              id: 'add-developer.view_developer',
            })}
            actionButtonCTA={redirectToDeveloperDetailsPage}
          />
        ) : (
          <Form
            id={formId}
            aria-labelledby="add-developer-heading"
            noValidate={true}
            onSubmit={handleFormSubmit}
          >
            <FormErrors formState={formState} />

            <FormSection>
              <ImageUploadControlled
                control={control}
                name="logo"
                title={formatMessage({
                  id: 'add-developer.upload_logo',
                })}
                onUploadImage={handleLogoUpload}
                folder={S3FolderEnum.ProjectDeveloper}
                logo={(developerDetails as any)?.media?.[0]}
              />

              <TextFieldControlled
                control={control}
                name="name"
                label={<FormattedMessage id="add-developer.name" />}
                size={Size.Loose}
                placeholder={formatMessage({
                  id: 'add-developer.name.placeholder',
                })}
                rules={{
                  required: formatMessage({
                    id: 'add-developer.name.message.required',
                  }),
                }}
              />

              <TextFieldControlled
                control={control}
                name="description"
                label={<FormattedMessage id="add-developer.description" />}
                placeholder={formatMessage({
                  id: 'add-developer.description.placeholder',
                })}
                rules={{
                  required: formatMessage({
                    id: 'add-developer.description.message.required',
                  }),
                }}
              />

              <TextFieldControlled
                control={control}
                name="website"
                label={<FormattedMessage id="add-developer.website" />}
                size={Size.Loose}
                rules={{
                  required: formatMessage({
                    id: 'add-developer.website.message.required',
                  }),
                }}
              />
            </FormSection>
          </Form>
        )}
      </DialogBody>

      {!developerAdded && (
        <DialogFooter>
          <Button onPress={closeModal}>
            <FormattedMessage id="add-developer.cancel_button" />
          </Button>
          <Button
            variant="primary"
            type="submit"
            form={formId}
            isLoading={upsertProjectDeveloperLoading}
          >
            <FormattedMessage id="add-developer.add_button" />
          </Button>
        </DialogFooter>
      )}
    </Dialog>
  );
}
