import {
  BannerMessage,
  ListItem,
  Paragraph,
  PlainButton,
  UnorderedList,
} from '@pledge-earth/product-language';
import { useEffect, useRef } from 'react';
import type { FieldError, FieldValues, FormState } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

interface FormErrorsProps<T extends FieldValues> {
  formState: FormState<T>;
  focusDelay?: number;
  alternateRoots?: string[];
}

export function FormErrors<T extends FieldValues>({
  alternateRoots,
  formState: { errors, isSubmitted, submitCount },
  focusDelay = 0,
}: FormErrorsProps<T>) {
  const formErrorRef = useRef<HTMLDivElement>(null);
  const fieldErrorsRef = useRef<HTMLDivElement>(null);
  const lastFocusedSubmit = useRef<number>(0);

  const fieldErrorKeys = Object.keys(errors).filter(
    (error) => !error.startsWith('root'),
  );

  const isShowingFormError =
    errors.root != null || alternateRoots?.some((root) => errors[root] != null);
  const isShowingFieldErrors = isSubmitted && fieldErrorKeys.length > 2;

  // Casting here to avoid a Typescript issue where it can't determine the type of root error object
  const alternativeRootErrors =
    alternateRoots?.map((root) => ({
      root,
      message: (errors[root]?.root as any)?.message,
    })) ?? [];

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;

    // Prevents moving focus in the case that the field errors banner appears after a failed submit but before the user
    // has finished editing the fields.
    if (submitCount <= lastFocusedSubmit.current) {
      return undefined;
    }

    if (isShowingFormError) {
      timeout = setTimeout(() => {
        formErrorRef.current?.focus();
      }, focusDelay);
      lastFocusedSubmit.current = submitCount;
    } else if (isShowingFieldErrors) {
      timeout = setTimeout(() => {
        fieldErrorsRef.current?.focus();
      }, focusDelay);
      lastFocusedSubmit.current = submitCount;
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [focusDelay, isShowingFieldErrors, isShowingFormError, submitCount]);

  if (!isShowingFormError && !isShowingFieldErrors) {
    return null;
  }

  return (
    <div className="flex flex-col gap-2">
      {isShowingFormError ? (
        <BannerMessage slot="formError" ref={formErrorRef}>
          <Paragraph className="mb-0">{errors.root?.message}</Paragraph>
          {/* Only show the alternative root errors if the global error is not set */}
          {!errors.root?.message
            ? alternativeRootErrors.map((error) => (
                <Paragraph key={error.root} className="mb-1">
                  {error.message}
                </Paragraph>
              ))
            : null}
        </BannerMessage>
      ) : null}

      {isShowingFieldErrors ? (
        <BannerMessage slot="fieldErrors" ref={fieldErrorsRef}>
          <Paragraph className="mb-0">
            <FormattedMessage id="form_errors.resolve_field_errors" />
          </Paragraph>
          <UnorderedList className="mb-0">
            {fieldErrorKeys.map((key) => {
              const error = errors[key];

              if (Array.isArray(error)) {
                // New fields may not have been validated yet
                const errorsList = error.filter((e) => e);

                return errorsList.map((error, index) =>
                  Object.keys(error).map((subKey) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <ListItem key={`${key}-${subKey}-${index}`}>
                      <PlainButton
                        className="px-0"
                        variant="monochrome"
                        onPress={() => error[subKey].ref?.focus?.()}
                      >
                        {error[subKey].message}
                      </PlainButton>
                    </ListItem>
                  )),
                );
              }

              const value = errors[key] as FieldError;

              return (
                <ListItem key={key}>
                  <PlainButton
                    className="px-0"
                    variant="monochrome"
                    onPress={() => {
                      value.ref?.focus?.();
                    }}
                  >
                    {value.message}
                  </PlainButton>
                </ListItem>
              );
            })}
          </UnorderedList>
        </BannerMessage>
      ) : null}
    </div>
  );
}
