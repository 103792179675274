import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Spin,
  // eslint-disable-next-line no-restricted-imports -- blocked by PPL combobox
  Select,
} from '@pledge-earth/web-components';
import {
  Heading,
  ContextualHelp,
  Size,
  Form,
  FormSection,
  FormSectionHeader,
} from '@pledge-earth/product-language';
import type { UseFormReturn } from 'react-hook-form';

import type { FormData } from '../PortfolioEdit';
import type { Client } from '../../../services/graphql/generated';
import { TextFieldControlled } from '../../../components/ReactHookForm/TextFieldControlled';
import { FormErrors } from '../../../components/ReactHookForm/FormErrors';
import { MultiSelectFieldControlled } from '../../../components/ReactHookForm/MultiSelectFieldControlled';

export function PortfolioEditSettings({
  formId,
  clientList,
  loadingClients,
  formMethods,
}: {
  formId: string;
  clientList: Pick<Client, 'id' | 'legal_name'>[] | undefined;
  loadingClients: boolean;
  formMethods: UseFormReturn<FormData>;
}) {
  const { formatMessage } = useIntl();
  const { control, formState } = formMethods;

  const clientsDropdownOptions = useMemo(() => {
    const dropdownOptions = clientList?.map((client) => (
      <Select.Option
        value={client.id}
        key={client.legal_name}
        label={client.legal_name}
      >
        {client.legal_name}
      </Select.Option>
    ));
    dropdownOptions?.push(
      <Select.Option
        value={null}
        selected={true}
        key={formatMessage({
          id: 'portfolio.edit.organisations-label',
        })}
        label={formatMessage({
          id: 'portfolio.edit.organisations-label',
        })}
      >
        {formatMessage({
          id: 'portfolio.edit.organisations-label',
        })}
      </Select.Option>,
    );
    return dropdownOptions;
  }, [clientList, formatMessage]);

  return (
    <Spin spinning={loadingClients}>
      <Form id={formId} noValidate={true} aria-label="Edit portfolio">
        <FormErrors formState={formState} />

        <FormSection>
          <FormSectionHeader>
            <Heading>
              <FormattedMessage id="portfolio.edit.entitlements" />
            </Heading>
          </FormSectionHeader>

          <MultiSelectFieldControlled
            control={control}
            name="portfolio_draft.acl"
            label={formatMessage({
              id: 'portfolio.edit.acl',
            })}
            size="large"
            defaultValue={null}
            filterOption={(input, option) =>
              option?.key?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            renderOptions={() => clientsDropdownOptions}
          />
        </FormSection>

        <FormSection>
          <FormSectionHeader>
            <Heading>
              <FormattedMessage id="portfolio.edit.accounting" />
            </Heading>
          </FormSectionHeader>

          <TextFieldControlled
            control={control}
            name="portfolio_draft.details.accounting_code"
            label={
              <>
                <FormattedMessage id="portfolio.edit.accounting_code" />
                <ContextualHelp>
                  <FormattedMessage id="portfolio.edit.accounting_code.tooltip" />
                </ContextualHelp>
              </>
            }
            size={Size.Loose}
            placeholder={formatMessage({
              id: 'portfolio.edit.accounting_code.placeholder',
            })}
          />

          <TextFieldControlled
            label={formatMessage({
              id: 'portfolio.edit.max_commission',
            })}
            size={Size.Loose}
            control={control}
            disabled={true}
            suffix="%"
            name="max_commission_percentage"
            rules={{
              required: formatMessage({
                id: 'portfolio.edit.max_commission.required',
              }),
            }}
          />
        </FormSection>
      </Form>
    </Spin>
  );
}
