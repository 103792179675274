import type { FieldProps } from '@pledge-earth/product-language';
import { Field } from '@pledge-earth/product-language';
import type { ForwardedRef } from 'react';

import { genericForwardRef } from '../../../utils/genericForwardRef';

import type { MultiSelectProps } from './MultiSelect';
import { MultiSelect } from './MultiSelect';

export type MultiSelectFieldProps = MultiSelectProps &
  Pick<FieldProps, 'label' | 'isInvalid' | 'errorMessage'>;

export const MultiSelectField = /* #__PURE__ */ genericForwardRef(
  // eslint-disable-next-line prefer-arrow-callback
  function MultiSelectField(
    {
      label,
      isInvalid,
      errorMessage,
      ...SelectAutocompleteProps
    }: MultiSelectFieldProps,
    forwardedRef: ForwardedRef<any>,
  ) {
    return (
      <Field label={label} isInvalid={isInvalid} errorMessage={errorMessage}>
        {(props) => (
          <MultiSelect
            {...props}
            {...SelectAutocompleteProps}
            ref={forwardedRef}
          />
        )}
      </Field>
    );
  },
);
