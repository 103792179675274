import type { PropsWithChildren } from 'react';
import { useEffect } from 'react';
import { Layout } from '@pledge-earth/web-components';

import { TopBar } from '../../components/TopBar';
import { Menu as MenuLeft } from '../../components/MenuLeft';
import { settingChanged } from '../../store/settings/reducers';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

import './Main.scss';

const { Header, Content } = Layout;

let touchStartPrev = 0;
let touchStartLocked = false;

export function MainLayout({ children = [] }: PropsWithChildren<unknown>) {
  const dispatch = useAppDispatch();
  const isMobileMenuOpen = useAppSelector(
    (state) => state.settings.isMobileMenuOpen,
  );

  // touch slide mobile menu opener
  const toggleMobileMenu = () => {
    dispatch(
      settingChanged({
        setting: 'isMobileMenuOpen',
        value: !isMobileMenuOpen,
      }),
    );
  };

  useEffect(() => {
    const unify = (e: any) => (e.changedTouches ? e.changedTouches[0] : e);
    document.addEventListener(
      'touchstart',
      (e) => {
        const x = unify(e).clientX;
        touchStartPrev = x;
        touchStartLocked = x > 70;
      },
      { passive: false },
    );
    document.addEventListener(
      'touchmove',
      (e) => {
        const x = unify(e).clientX;
        const prev = touchStartPrev;
        if (x - prev > 50 && !touchStartLocked) {
          toggleMobileMenu();
          touchStartLocked = true;
        }
      },
      { passive: false },
    );
  });

  return (
    <Layout className="MainLayout">
      <MenuLeft />
      <Layout className="MainLayout__right">
        <Header className="MainLayout__header">
          <TopBar />
        </Header>
        <Content className="MainLayout__content">
          <div className="flex min-w-0 flex-wrap">
            <div className="relative min-h-px max-w-full grow-0 shrink-0 basis-full">
              {children}
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}
