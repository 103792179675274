import { useCallback, useId, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { omit } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { Spin } from '@pledge-earth/web-components';
import {
  Dialog,
  DialogHeader,
  DialogTitle,
  OverlayCloseButton,
  DialogBody,
  DialogFooter,
  Button,
} from '@pledge-earth/product-language';
import { useMutation, useQuery } from '@apollo/client';
import { useForm, useWatch } from 'react-hook-form';

import type { PortfolioDraftInput } from '../../services/graphql/generated';
import {
  CurrencyEnum,
  CreatePortfolioDocument,
  GetClientsIdNameDocument,
  UpsertPortfolioDraftDocument,
} from '../../services/graphql/generated';
import { EntityAdded } from '../../components/AddEntity/EntityAdded';
import { showSuccessToast } from '../../utils/toast';
import { useRootFormError } from '../../components/ReactHookForm/useRootFormError';

import './PortfolioEdit.scss';
import { PortfolioEditAllocations } from './portfolioEdit/PortfolioEditAllocations';
import { PortfolioEditSettings } from './portfolioEdit/PortfolioEditSettings';
import { PortfolioEditDetails } from './portfolioEdit/PortfolioEditDetails';
import { PortfolioEditSummary } from './portfolioEdit/PortfolioEditSummary';

// This should conform to the order of pages you want to show
// eslint-disable-next-line @typescript-eslint/naming-convention -- eslint onboarding
enum PORTFOLIO_FORM_STATES {
  ALLOCATIONS = 'ALLOCATIONS',
  ENTITLEMENTS_AND_ACCOUNTING = 'ENTITLEMENTS_AND_ACCOUNTING',
  DETAILS = 'DETAILS',
  SUMMARY = 'SUMMARY',
}

export type FormData = PortfolioDraftInput & {
  max_commission_percentage: number;
};

export function PortfolioEdit({
  closeModal,
  onSaved,
  portfolio,
}: {
  closeModal: () => void;
  portfolio?: PortfolioDraftInput;
  onSaved?: () => void;
}) {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const [showSaveDraftSuccess, setShowSaveDraftSuccess] = useState(false);
  const [showSaveCreateSuccess, setShowSaveCreateSuccess] = useState(false);
  const [formProgressionState, setFormProgressionState] =
    useState<PORTFOLIO_FORM_STATES>(PORTFOLIO_FORM_STATES.ALLOCATIONS);
  const formId = useId();

  const [
    upsertPortfolioMutation,
    { loading: draftLoading, error: draftError },
  ] = useMutation(UpsertPortfolioDraftDocument);

  const [
    createPortfolioMutation,
    { loading: createLoading, error: createError },
  ] = useMutation(CreatePortfolioDocument);

  const { data: dataClients, loading: loadingClients } = useQuery(
    GetClientsIdNameDocument,
  );

  const formMethods = useForm<FormData>({
    errors: useRootFormError(draftError || createError),
    defaultValues: {
      max_commission_percentage: 20,
      portfolio_draft: {
        acl: portfolio?.portfolio_draft.acl ?? null,
        details: {
          name: portfolio?.portfolio_draft.details?.name ?? null,
          portfolio_version:
            portfolio?.portfolio_draft.details?.portfolio_version ?? null,
          description: portfolio?.portfolio_draft.details?.description ?? null,
          currency: CurrencyEnum.Gbp,
          accounting_code:
            portfolio?.portfolio_draft?.details?.accounting_code ?? null,
          key_facts: portfolio?.portfolio_draft.details?.key_facts ?? [
            '',
            '',
            '',
            '',
          ],
        },
        allocations: portfolio?.portfolio_draft.allocations ?? [],
      },
    },
  });

  const portfolioDraft = useWatch({
    control: formMethods.control,
    name: 'portfolio_draft',
  });
  const id = useWatch({ control: formMethods.control, name: 'id' });

  const sanitizePortfolio = () => {
    const { acl, details, allocations } = portfolioDraft;
    const sanitizedAllocations = allocations?.map((allocation) => {
      const sanitizedAllocation = omit(allocation, '__typename');
      return sanitizedAllocation;
    });
    const sanitizedDetails = omit(details, '__typename');
    const sanitizedPortfolio = {
      id,
      portfolio_draft: {
        details: sanitizedDetails,
        allocations: sanitizedAllocations,
        acl,
      },
    };

    return sanitizedPortfolio;
  };

  const saveAsDraft = async () => {
    await upsertPortfolioMutation({
      variables: {
        data: sanitizePortfolio(),
      },
    });

    if (onSaved) {
      showSuccessToast({
        description: 'Portfolio draft has been saved',
      });
      onSaved();
    } else {
      setShowSaveDraftSuccess(true);
    }
  };

  const createPortfolio = async () => {
    await createPortfolioMutation({
      variables: {
        data: sanitizePortfolio(),
      },
    });

    if (onSaved) {
      showSuccessToast({
        description: 'Portfolio has been created',
      });
      onSaved();
    } else {
      setShowSaveCreateSuccess(true);
    }
  };

  const onContinue = () => {
    const states = Object.values(PORTFOLIO_FORM_STATES);
    const currentIndex = states.findIndex(
      (state) => state === formProgressionState,
    );
    if (currentIndex < states.length) {
      setFormProgressionState(states[currentIndex + 1]!);
    }
  };

  const onBack = () => {
    const states = Object.values(PORTFOLIO_FORM_STATES);
    const currentIndex = states.findIndex(
      (state) => state === formProgressionState,
    );
    if (currentIndex > 0) {
      setFormProgressionState(states[currentIndex - 1]!);
    }
  };

  const redirectToPortfoliosTablePage = () => {
    navigate('/offsetting/portfolios');
    closeModal();
  };

  const handleClose = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const clientName = useMemo(
    () =>
      dataClients?.clients.items.find(
        (client) => client.id === portfolioDraft?.acl?.[0],
      )?.legal_name,
    [dataClients?.clients.items, portfolioDraft?.acl],
  );

  const isLoading = createLoading || draftLoading;

  const getPortfolioFormPage = () => {
    switch (formProgressionState) {
      case PORTFOLIO_FORM_STATES.ALLOCATIONS:
        return (
          <PortfolioEditAllocations formId={formId} formMethods={formMethods} />
        );
      case PORTFOLIO_FORM_STATES.ENTITLEMENTS_AND_ACCOUNTING:
        return (
          <PortfolioEditSettings
            formId={formId}
            clientList={dataClients?.clients.items}
            loadingClients={loadingClients}
            formMethods={formMethods}
          />
        );
      case PORTFOLIO_FORM_STATES.DETAILS:
        return (
          <PortfolioEditDetails
            formId={formId}
            clientName={clientName}
            formMethods={formMethods}
          />
        );
      case PORTFOLIO_FORM_STATES.SUMMARY:
        return (
          <PortfolioEditSummary
            clientName={clientName}
            formMethods={formMethods}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Dialog>
      <DialogHeader>
        <DialogTitle>
          <FormattedMessage id="portfolio.edit.title" />
        </DialogTitle>
        <OverlayCloseButton label={formatMessage({ id: 'close' })} />
      </DialogHeader>

      <DialogBody>
        {showSaveDraftSuccess ? (
          <EntityAdded
            closeModal={handleClose}
            entityAddedTitle="Portfolio draft saved"
            actionButtonCTAText="View in portfolios table"
            actionButtonCTA={redirectToPortfoliosTablePage}
          />
        ) : null}
        {showSaveCreateSuccess ? (
          <EntityAdded
            closeModal={handleClose}
            entityAddedTitle="Portfolio created"
            actionButtonCTAText="View portfolios table"
            actionButtonCTA={redirectToPortfoliosTablePage}
          />
        ) : null}
        {!showSaveDraftSuccess && !showSaveCreateSuccess && (
          <Spin spinning={isLoading}>{getPortfolioFormPage()}</Spin>
        )}
      </DialogBody>

      {!showSaveDraftSuccess && !showSaveCreateSuccess && (
        <DialogFooter>
          <Button onPress={onBack}>
            <FormattedMessage id="portfolio.edit.buttons.back" />
          </Button>

          <Button onPress={saveAsDraft} variant="default" className="ml-auto">
            <FormattedMessage id="portfolio.edit.buttons.save-as-draft" />
          </Button>
          {formProgressionState === PORTFOLIO_FORM_STATES.SUMMARY ? (
            <Button
              onPress={createPortfolio}
              variant="primary"
              type="submit"
              form={formId}
            >
              <FormattedMessage id="portfolio.edit.buttons.create-portfolio" />
            </Button>
          ) : (
            <Button onPress={onContinue} variant="primary" form={formId}>
              <FormattedMessage id="portfolio.edit.buttons.continue" />
            </Button>
          )}
        </DialogFooter>
      )}
    </Dialog>
  );
}
