import { all, takeEvery } from 'redux-saga/effects';
import store from 'store';
import qs from 'qs';

// eslint-disable-next-line import/no-cycle -- eslint onboarding
import { store as reduxStore } from '../store';
import { router } from '../../router';

import { settingChanged } from './reducers';

function* changeSetting({
  payload: { setting, value },
}: ReturnType<typeof settingChanged>) {
  yield store.set(`app.settings.${setting}`, value);
}

function* setup() {
  // load settings from url on app load
  const changeSettings = (search: string) => {
    const query = qs.parse(search, { ignoreQueryPrefix: true });
    Object.keys(query).forEach((key) => {
      let value: any;
      switch (query[key]) {
        case 'false':
          value = false;
          break;
        case 'true':
          value = true;
          break;
        default:
          value = query[key];
          break;
      }
      reduxStore.dispatch(
        settingChanged({
          setting: key as any,
          value,
        }),
      );
    });
  };
  yield changeSettings(window.location.search);
  yield router.subscribe((update) => {
    changeSettings(update.location.search);
  });

  // detect isMobileView setting on app load and window resize
  const isMobileView = (load = false) => {
    const currentState = globalThis.window.innerWidth < 768;
    const prevState = store.get('app.settings.isMobileView');
    if (currentState !== prevState || load) {
      reduxStore.dispatch(
        settingChanged({
          setting: 'isMobileView',
          value: currentState,
        }),
      );
    }
  };

  // detect viewport width on app load and window resize
  const isMenuToggled = () => {
    const shouldToggle = globalThis.window.innerWidth < 1024;
    const prevState = store.get('app.settings.isMenuCollapsed');
    if (shouldToggle || prevState) {
      reduxStore.dispatch(
        settingChanged({
          setting: 'isMenuCollapsed',
          value: true,
        }),
      );
    }
  };

  yield isMobileView(true);
  yield isMenuToggled();
  yield window.addEventListener('resize', () => {
    isMobileView();
    isMenuToggled();
  });
}

export function* rootSaga() {
  yield all([
    takeEvery(settingChanged, changeSetting),

    setup(), // run once on app load to init listeners
  ]);
}
