import type React from 'react';
import { useState } from 'react';
import {
  CategoryFilledIcon,
  Dialog,
  DialogBody,
  DialogHeader,
  DialogTitle,
  FileSpreadsheetIcon,
  ImagesIcon,
  ImageIcon,
  OverlayCloseButton,
  ProjectCertificateIcon,
  ProjectDeveloperIcon,
  ProjectOwnerIcon,
  Separator,
} from '@pledge-earth/product-language';

import { RegistryEdit as AddRegistry } from '../../pages/registries/RegistryEdit';
import { ProjectOwnerEdit as AddProjectOwners } from '../../pages/projectOwners/ProjectOwnerEdit';
import { ProjectTypeEdit as AddProjectTypes } from '../../pages/projectTypes/ProjectTypeEdit';
import { ProjectCertificationEdit as AddProjectCertification } from '../../pages/projectCertifications/ProjectCertificationEdit';
import { PortfolioEdit as AddPortfolio } from '../../pages/portfolios/PortfolioEdit';

import { AddProject } from './AddProject/AddProject';
import { AddDeveloper } from './AddDeveloper/AddDeveloper';
import { AddSection } from './AddSection';
import './AddEntity.scss';

function EntityIcon({
  children,
  pink,
  green,
}: {
  children: React.ReactNode;
  pink?: boolean;
  green?: boolean;
}) {
  return (
    <span
      className={
        // eslint-disable-next-line no-nested-ternary
        pink
          ? 'text-icon-accent'
          : green
            ? 'text-icon-success'
            : 'text-icon-default'
      }
    >
      {children}
    </span>
  );
}

export function AddEntity({ closeModal }: { closeModal: () => void }) {
  const [selectedEntity, setSelectedEntity] = useState<string | null>(null);

  switch (selectedEntity) {
    case 'DEVELOPER':
      return <AddDeveloper closeModal={closeModal} />;
    case 'OWNER':
      return <AddProjectOwners closeModal={closeModal} />;
    case 'CERTIFICATION':
      return <AddProjectCertification closeModal={closeModal} />;
    case 'PROJECT_TYPE':
      return <AddProjectTypes closeModal={closeModal} />;
    case 'REGISTRY':
      return <AddRegistry closeModal={closeModal} />;
    case 'PROJECT':
      return <AddProject closeModal={closeModal} />;
    case 'PORTFOLIO':
      return <AddPortfolio closeModal={closeModal} />;
    case null:
    default:
      return <ChooseEntity onEntitySelected={setSelectedEntity} />;
  }
}

function ChooseEntity({
  onEntitySelected,
}: {
  onEntitySelected: (entity: string) => void;
}) {
  return (
    <Dialog>
      <DialogHeader>
        <DialogTitle>Add new</DialogTitle>
        <OverlayCloseButton label="Close" />
      </DialogHeader>
      <DialogBody className="AddEntity__container">
        <div className="flex gap-1 flex-col">
          <div className="AddEntity__container__row">
            <AddSection
              Icon={
                <EntityIcon green={true}>
                  <ImagesIcon />
                </EntityIcon>
              }
              entityName="Portfolio"
              onPress={() => onEntitySelected('PORTFOLIO')}
            />
            <AddSection
              Icon={
                <EntityIcon green={true}>
                  <ImageIcon />
                </EntityIcon>
              }
              entityName="Project"
              onPress={() => onEntitySelected('PROJECT')}
            />
            <AddSection
              Icon={
                <EntityIcon green={true}>
                  <ProjectDeveloperIcon />
                </EntityIcon>
              }
              entityName="Developer"
              onPress={() => onEntitySelected('DEVELOPER')}
            />
            <AddSection
              Icon={
                <EntityIcon green={true}>
                  <ProjectOwnerIcon />
                </EntityIcon>
              }
              entityName="Owner"
              onPress={() => onEntitySelected('OWNER')}
            />
            <AddSection
              Icon={
                <EntityIcon green={true}>
                  <ProjectCertificateIcon />
                </EntityIcon>
              }
              entityName="Certification"
              onPress={() => onEntitySelected('CERTIFICATION')}
            />
            <AddSection
              Icon={
                <EntityIcon green={true}>
                  <CategoryFilledIcon />
                </EntityIcon>
              }
              entityName="Type"
              onPress={() => onEntitySelected('PROJECT_TYPE')}
            />
          </div>
          <Separator className="my-6" />
          <div className="AddEntity__container__row">
            <AddSection
              Icon={
                <EntityIcon pink={true}>
                  <FileSpreadsheetIcon />
                </EntityIcon>
              }
              entityName="Registry"
              onPress={() => onEntitySelected('REGISTRY')}
            />
          </div>
        </div>
      </DialogBody>
    </Dialog>
  );
}
