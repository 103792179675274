import type { TypedDocumentNode } from '@apollo/client';
import type { ControllerProps, FieldPath, FieldValues } from 'react-hook-form';
import { Controller } from 'react-hook-form';

import type { MultiSelectAutocompleteFieldProps } from '../Input/MultiSelectAutocomplete/MultiSelectAutocompleteField';
import { MultiSelectAutocompleteField } from '../Input/MultiSelectAutocomplete/MultiSelectAutocompleteField';

type Props<
  TQuery extends TypedDocumentNode<any, any>,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<ControllerProps<TFieldValues, TName>, 'render'> &
  Omit<
    MultiSelectAutocompleteFieldProps<TQuery>,
    | 'ref'
    | 'value'
    | 'onChange'
    | 'onBlur'
    | 'disabled'
    | 'isInvalid'
    | 'errorMessage'
  >;

/** a SelectAutocompleteField wrapped in a controller */
export function MultiSelectAutocompleteFieldControlled<
  TQuery extends TypedDocumentNode<any, any>,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  defaultValue,
  rules,
  shouldUnregister,
  disabled,
  ...rest
}: Props<TQuery, TFieldValues, TName>) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      shouldUnregister={shouldUnregister}
      disabled={disabled}
      render={({
        field: { ref, value, onChange, onBlur, disabled },
        fieldState: { invalid, error },
      }) => (
        <MultiSelectAutocompleteField
          ref={ref}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          isInvalid={invalid}
          errorMessage={error?.message}
          // Forward remaining props to SelectAutocompleteField.
          {...rest}
        />
      )}
    />
  );
}
