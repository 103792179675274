import { useMemo } from 'react';

export function useRootFormError(
  error: Error | undefined,
  { message }: { message?: string } = {},
) {
  return useMemo(() => {
    if (error == null) {
      return undefined;
    }

    return {
      root: {
        message: message || error.message,
      },
    };
  }, [error, message]);
}
