import type { ReactNode, Ref, RefAttributes } from 'react';
import { forwardRef } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-types
type GenericForwardRef = <T, P = {}>(
  render: (props: P, ref: Ref<T>) => ReactNode,
) => (props: P & RefAttributes<T>) => ReactNode;

/**
 * forwardRef that works with generics
 */
export const genericForwardRef = forwardRef as GenericForwardRef;
